import { __makeTemplateObject } from "tslib";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
var _Link = function (_a) {
    var path = _a.path, title = _a.title, className = _a.className, children = _a.children, active = _a.active, primary = _a.primary;
    var content = (React.createElement(StyledButton, null,
        title,
        children && primary && React.createElement(ArrowDown, null),
        children && !primary && React.createElement(ArrowRight, null)));
    if (!path)
        return content;
    else if (path.startsWith("/"))
        return (React.createElement(RouterLink, { to: path, className: className, "data-testid": "link-" + title }, content));
    else
        return (React.createElement("a", { className: className, href: path, "data-testid": "link-" + title }, content));
};
export var Link = styled(_Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-decoration: none;\n  color: ", ";\n"], ["\n  text-decoration: none;\n  color: ", ";\n"])), function (props) { return (props.primary ? "white" : "black"); });
var StyledButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 1px 0;\n"], ["\n  padding: 1px 0;\n"])));
var templateObject_1, templateObject_2;
