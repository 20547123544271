import { __assign, __makeTemplateObject, __read, __rest } from "tslib";
import React, { useRef, useState, useCallback, useMemo, useEffect, } from "react";
import styled from "styled-components";
import { Popover } from "../../components/Popover";
import { Colors } from "../../constants";
import { useOutsideClick } from "../../hooks";
export var DropdownMenuButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 8px 16px;\n  font-family: inherit;\n  font-size: 14px;\n  line-height: 20px;\n  color: ", ";\n  border: none;\n  outline: none;\n  display: flex;\n  background: transparent;\n  border-radius: 4px;\n\n  &:hover {\n    background: ", ";\n    color: white;\n  }\n"], ["\n  padding: 8px 16px;\n  font-family: inherit;\n  font-size: 14px;\n  line-height: 20px;\n  color: ", ";\n  border: none;\n  outline: none;\n  display: flex;\n  background: transparent;\n  border-radius: 4px;\n\n  &:hover {\n    background: ", ";\n    color: white;\n  }\n"])), Colors.PrimaryBlack, Colors.PrimaryBlue);
var StyledTooltip = styled(Popover)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  background: white;\n\n  & > div {\n    padding: 16px 8px;\n  }\n  button {\n    &:hover {\n      background: ", ";\n      color: ", ";\n    }\n\n    &:active {\n      background: ", ";\n      outline: none;\n      color: white;\n    }\n\n    &:focus {\n      outline: none;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  background: white;\n\n  & > div {\n    padding: 16px 8px;\n  }\n  button {\n    &:hover {\n      background: ", ";\n      color: ", ";\n    }\n\n    &:active {\n      background: ", ";\n      outline: none;\n      color: white;\n    }\n\n    &:focus {\n      outline: none;\n    }\n  }\n"])), Colors.Gray2, Colors.PrimaryBlack, Colors.PrimaryBlue);
export var DropdownMenu = function (_a) {
    var children = _a.children, referenceElement = _a.referenceElement, reference = _a.reference, rest = __rest(_a, ["children", "referenceElement", "reference"]);
    var triggerRef = useRef(null);
    var dropdownRef = useRef(null);
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var toggle = useCallback(function () {
        setIsOpen(function (state) { return !state; });
    }, []);
    var close = useCallback(function () {
        setIsOpen(false);
    }, []);
    var referenceSet = useMemo(function () {
        var set = [dropdownRef];
        if (referenceElement)
            set.push(triggerRef);
        if (reference)
            set.push(reference);
        return set;
    }, [reference, referenceElement]);
    useOutsideClick(referenceSet, close);
    var onClickContent = useCallback(function (e) {
        if (e.target instanceof HTMLButtonElement)
            close();
    }, [close]);
    useEffect(function () {
        if (!reference)
            return;
        var refElement = reference.current;
        refElement === null || refElement === void 0 ? void 0 : refElement.addEventListener("click", toggle);
        return function () { return refElement === null || refElement === void 0 ? void 0 : refElement.removeEventListener("click", toggle); };
    }, [reference, toggle]);
    return (React.createElement(React.Fragment, null,
        referenceElement &&
            React.cloneElement(referenceElement, {
                ref: triggerRef,
                onClick: toggle,
            }),
        React.createElement(StyledTooltip, __assign({}, rest, { isOpen: isOpen, reference: reference || triggerRef, ref: dropdownRef, isLight: true, placement: "bottom", onClickContent: onClickContent }), children)));
};
var templateObject_1, templateObject_2;
