export var generateTestId = function (prefix, identifier) {
    var result = prefix;
    typeof identifier === "string" &&
        (result += "" + (prefix !== "" ? "-" : "") + identifier);
    return result.toLowerCase().replace(/ /g, "-");
};
export var populateTestId = function (props, input, prefix) {
    if (!props["data-testid"] && typeof input === "string")
        props["data-testid"] = "" + generateTestId(prefix, input);
};
