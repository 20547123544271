import { __assign, __makeTemplateObject, __read, __spread } from "tslib";
import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Spinner } from "../../Spinner";
import { ReactComponent as UploadIcon } from "./upload.svg";
import styled from "styled-components";
import { H5, Body3 } from "../../Text";
import { Colors } from "../../../constants/colors";
import { FormElement } from "../FormElement";
import { v4 as uuidv4 } from "uuid";
import { FilePreview } from "./FilePreview";
export var FileInput = forwardRef(function (_a, ref) {
    var label = _a.label, name = _a.name, multiple = _a.multiple, accept = _a.accept, onLoad = _a.onLoad, onChange = _a.onChange, readAsText = _a.readAsText, value = _a.value, error = _a.error, isBusy = _a.isBusy, _b = _a.className, className = _b === void 0 ? "" : _b, _c = _a.isFormElement, isFormElement = _c === void 0 ? true : _c, _d = _a.showPreview, showPreview = _d === void 0 ? true : _d, isMulti = _a.isMulti;
    var _e = __read(useState(value ? (Array.isArray(value) ? value : [value]) : []), 2), files = _e[0], setFiles = _e[1];
    var onDrop = useCallback(function (incomingFiles) {
        incomingFiles.forEach(function (file) {
            var reader = new FileReader();
            reader.onload = function (event) {
                var extendedFile = {
                    baseFile: file,
                    type: file.type,
                    name: file.name,
                    size: file.size,
                    path: file.path,
                    content: event.target.result,
                    id: uuidv4(),
                };
                setFiles(function (files) {
                    var newFiles = isMulti
                        ? __spread(files, [extendedFile]) : [extendedFile];
                    onChange && onChange(isMulti ? newFiles : newFiles[0]);
                    return newFiles;
                });
                onLoad && onLoad(extendedFile);
            };
            reader[readAsText ? "readAsText" : "readAsDataURL"](file);
        });
    }, [onLoad, readAsText, onChange, isMulti]);
    var _f = useDropzone({
        onDrop: onDrop,
        multiple: multiple,
        accept: accept,
    }), getRootProps = _f.getRootProps, getInputProps = _f.getInputProps, isDragActive = _f.isDragActive;
    var acceptedFormats = useMemo(function () {
        if (!accept)
            return undefined;
        return accept
            .split(",")
            .map(function (type) {
            var format = type
                .substr(type.indexOf("/") + 1)
                .replace(/ /g, "")
                .toUpperCase();
            if (!format.startsWith("."))
                format = "." + format;
            return format;
        })
            .filter(function (value, index, array) { return array.indexOf(value) === index; });
    }, [accept]);
    var removeFile = useCallback(function (id) {
        setFiles(function (files) {
            var newFiles = files.filter(function (file) { return file.id !== id; });
            onChange && onChange(isMulti ? newFiles : undefined);
            return newFiles;
        });
    }, [onChange, isMulti]);
    var component = (React.createElement(React.Fragment, null,
        React.createElement(DropArea, __assign({}, getRootProps(), { className: "drop-area " + className, isInvalid: !!error, tabIndex: -1, ref: ref }),
            React.createElement("input", __assign({}, getInputProps(), { "data-testid": name })),
            React.createElement(EmptyStateContainer, null,
                React.createElement(StyledUploadIcon, null),
                React.createElement(EmptyStateContainerRightColumn, null,
                    isDragActive && React.createElement(H5, { as: "span" }, "Drop the files here ..."),
                    !isDragActive && (React.createElement(React.Fragment, null,
                        React.createElement(H5, { as: "span" },
                            "Drop files to attach, or",
                            " ",
                            React.createElement(H5, { color: Colors.PrimaryBlue, as: "span" }, "browse")),
                        acceptedFormats && (React.createElement(AcceptedFormatsText, null,
                            "Only ",
                            acceptedFormats.join(", "),
                            " files.")))))),
            isBusy && React.createElement(Spinner, { blue: true, height: 40, width: 40 })),
        showPreview && files.length > 0 && (React.createElement(PreviewContainer, null, files.map(function (file) { return (React.createElement(FilePreview, { file: file, key: file.id, onClickRemoveFile: removeFile })); })))));
    return isFormElement ? (React.createElement(FormElement, { label: label, error: typeof error === "string" ? error : undefined }, component)) : (component);
});
var StyledUploadIcon = styled(UploadIcon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  margin-top: auto;\n  margin-bottom: auto;\n  margin-right: 16px;\n"], ["\n  display: block;\n  margin-top: auto;\n  margin-bottom: auto;\n  margin-right: 16px;\n"])));
var EmptyStateContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var EmptyStateContainerRightColumn = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var AcceptedFormatsText = styled(Body3).attrs(function (props) { return ({
    color: Colors.Gray5,
    ...props,
}); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 4px;\n"], ["\n  margin-top: 4px;\n"])));
var DropArea = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border: 2px dashed ", ";\n  display: flex;\n  justify-content: center;\n  position: relative;\n  padding: 16px 32px;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n  }\n"], ["\n  border: 2px dashed ", ";\n  display: flex;\n  justify-content: center;\n  position: relative;\n  padding: 16px 32px;\n  cursor: pointer;\n\n  &:focus {\n    outline: none;\n  }\n"])), function (props) { return (props.isInvalid ? Colors.Red : Colors.Gray4); });
var PreviewContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 16px;\n"], ["\n  margin-top: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
