import { __assign, __awaiter, __generator, __makeTemplateObject, __read, __rest } from "tslib";
import React, { useState, useCallback, memo, forwardRef, } from "react";
import styled from "styled-components";
import { shade, tint } from "polished";
import { Colors } from "../../constants";
import { useMountedCheck } from "../../hooks/useMountedCheck";
import { populateTestId } from "../../utils/generateTestId";
import { InlineSpinner } from "../../components/InlineSpinner";
import { useInnerRef } from "../../hooks/useInnerRef";
export var ButtonKind;
(function (ButtonKind) {
    ButtonKind[ButtonKind["PRIMARY"] = 0] = "PRIMARY";
    ButtonKind[ButtonKind["SECONDARY"] = 1] = "SECONDARY";
    ButtonKind[ButtonKind["GHOST"] = 2] = "GHOST";
})(ButtonKind || (ButtonKind = {}));
export var Button = memo(forwardRef(function (_a, ref) {
    var icon = _a.icon, children = _a.children, _b = _a.color, color = _b === void 0 ? Colors.PrimaryBlue : _b, _c = _a.kind, kind = _c === void 0 ? ButtonKind.PRIMARY : _c, onClick = _a.onClick, small = _a.small, disabled = _a.disabled, _isBusy = _a.busy, _d = _a.iconPlacement, iconPlacement = _d === void 0 ? "end" : _d, rest = __rest(_a, ["icon", "children", "color", "kind", "onClick", "small", "disabled", "busy", "iconPlacement"]);
    var _e = __read(useState(false), 2), isBusyInternal = _e[0], setIsBusy = _e[1];
    var isMounted = useMountedCheck();
    var innerRef = useInnerRef(ref);
    var isBusy = _isBusy || isBusyInternal;
    var action = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var prevWidth, prevHeight, _a, clientWidth, clientHeight, callbackResponse, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!onClick || !innerRef.current)
                        return [2 /*return*/];
                    prevWidth = innerRef.current.style.width;
                    prevHeight = innerRef.current.style.height;
                    _a = innerRef.current, clientWidth = _a.clientWidth, clientHeight = _a.clientHeight;
                    setIsBusy(true);
                    innerRef.current.style.width = clientWidth + "px";
                    innerRef.current.style.height = clientHeight + "px";
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 4, , 5]);
                    callbackResponse = onClick(event);
                    if (!(callbackResponse instanceof Promise)) return [3 /*break*/, 3];
                    return [4 /*yield*/, callbackResponse];
                case 2:
                    _c.sent();
                    _c.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    _b = _c.sent();
                    if (isMounted.current)
                        setIsBusy(false);
                    innerRef.current.style.width = prevWidth;
                    innerRef.current.style.height = prevHeight;
                    return [3 /*break*/, 5];
                case 5:
                    if (isMounted.current) {
                        setIsBusy(false);
                        innerRef.current.style.width = prevWidth;
                        innerRef.current.style.height = prevHeight;
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [onClick, isMounted, innerRef]);
    populateTestId(rest, children, "button");
    var StyledButton;
    switch (kind) {
        case ButtonKind.PRIMARY:
            StyledButton = PrimaryButton;
            break;
        case ButtonKind.SECONDARY:
            StyledButton = SecondaryButton;
            break;
        case ButtonKind.GHOST:
            StyledButton = GhostButton;
            break;
    }
    return (React.createElement(StyledButton, __assign({ onClick: action, disabled: disabled || isBusy, ref: innerRef, backgroundColor: color, iconOnly: Boolean(!children && icon), small: small }, rest),
        isBusy && (React.createElement(InlineSpinner, { height: small ? 16 : 20, width: small ? 16 : 20 })),
        !isBusy && (React.createElement(React.Fragment, null,
            iconPlacement === "start" && icon,
            children,
            iconPlacement === "end" && icon))));
}));
var PrimaryButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: white;\n  border: 1px solid;\n  border-color: ", ";\n  border-radius: 4px;\n  padding: ", ";\n  background: ", ";\n  font-weight: 600;\n  font-size: ", ";\n  line-height: ", ";\n  vertical-align: middle;\n  outline: none;\n  font-family: inherit;\n  display: inline-flex;\n  text-align: center;\n  justify-content: center;\n  white-space: nowrap;\n\n  :hover:not(:disabled):not(:active) {\n    border-color: transparent;\n    background: ", ";\n    color: white;\n\n    svg {\n      path {\n        fill: white;\n      }\n    }\n  }\n  :active {\n    background: ", ";\n    border-color: ", ";\n    box-shadow: none !important;\n    color: white;\n\n    svg {\n      path {\n        fill: white;\n      }\n    }\n  }\n  :focus {\n    box-shadow: 0px 0px 0px 2px ", ";\n    outline: 1px ", ";\n  }\n  :disabled {\n    border-color: transparent;\n    background: ", ";\n    color: white;\n    svg {\n      path {\n        fill: white;\n      }\n    }\n  }\n\n  svg {\n    height: 16px;\n    width: 16px;\n    margin-top: auto;\n    margin-bottom: auto;\n    ", "\n\n    path {\n      fill: white;\n    }\n  }\n\n  .spinner,\n  .inline-spinner {\n    svg {\n      margin: 0;\n    }\n  }\n"], ["\n  color: white;\n  border: 1px solid;\n  border-color: ", ";\n  border-radius: 4px;\n  padding: ",
    ";\n  background: ", ";\n  font-weight: 600;\n  font-size: ", ";\n  line-height: ", ";\n  vertical-align: middle;\n  outline: none;\n  font-family: inherit;\n  display: inline-flex;\n  text-align: center;\n  justify-content: center;\n  white-space: nowrap;\n\n  :hover:not(:disabled):not(:active) {\n    border-color: transparent;\n    background: ", ";\n    color: white;\n\n    svg {\n      path {\n        fill: white;\n      }\n    }\n  }\n  :active {\n    background: ", ";\n    border-color: ", ";\n    box-shadow: none !important;\n    color: white;\n\n    svg {\n      path {\n        fill: white;\n      }\n    }\n  }\n  :focus {\n    box-shadow: 0px 0px 0px 2px ", ";\n    outline: 1px ", ";\n  }\n  :disabled {\n    border-color: transparent;\n    background: ", ";\n    color: white;\n    svg {\n      path {\n        fill: white;\n      }\n    }\n  }\n\n  svg {\n    height: 16px;\n    width: 16px;\n    margin-top: auto;\n    margin-bottom: auto;\n    ",
    "\n\n    path {\n      fill: white;\n    }\n  }\n\n  .spinner,\n  .inline-spinner {\n    svg {\n      margin: 0;\n    }\n  }\n"])), function (props) { return props.backgroundColor; }, function (props) {
    if (props.small)
        return props.iconOnly ? "7px" : "7px 15px";
    else
        return props.iconOnly ? "9px" : "7px 23px";
}, function (props) { return props.backgroundColor; }, function (props) { return (props.small ? "12px" : "14px"); }, function (props) { return (props.small ? "16px" : "20px"); }, function (props) { return shade(0.25, props.backgroundColor); }, function (props) { return shade(0.5, props.backgroundColor); }, function (props) { return shade(0.5, props.backgroundColor); }, function (props) { return tint(0.5, props.backgroundColor); }, function (props) { return tint(0.5, props.backgroundColor); }, Colors.Gray4, function (props) {
    return "margin-" + (props.iconPlacement === "end" ? "left" : "right") + ":  " + (props.iconOnly ? "8" : "8px") + ";";
});
var SecondaryButton = styled(PrimaryButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  svg {\n    path {\n      fill: ", ";\n    }\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  svg {\n    path {\n      fill: ", ";\n    }\n  }\n"])), Colors.Gray1, function (props) { return props.backgroundColor; }, function (props) { return props.backgroundColor; });
var GhostButton = styled(PrimaryButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-color: transparent;\n  background: transparent !important;\n  color: ", " !important;\n  svg {\n    path {\n      fill: ", " !important;\n    }\n  }\n\n  :hover {\n    background: ", " !important;\n  }\n\n  :active {\n    background: ", " !important;\n    border-color: ", " !important;\n  }\n  :disabled {\n    color: ", " !important;\n    svg {\n      path {\n        fill: ", " !important;\n      }\n    }\n  }\n"], ["\n  border-color: transparent;\n  background: transparent !important;\n  color: ", " !important;\n  svg {\n    path {\n      fill: ", " !important;\n    }\n  }\n\n  :hover {\n    background: ", " !important;\n  }\n\n  :active {\n    background: ", " !important;\n    border-color: ", " !important;\n  }\n  :disabled {\n    color: ", " !important;\n    svg {\n      path {\n        fill: ", " !important;\n      }\n    }\n  }\n"])), function (props) { return props.backgroundColor; }, function (props) { return props.backgroundColor; }, Colors.Gray2, Colors.Gray3, Colors.Gray3, tint(0.5, Colors.PrimaryBlack), tint(0.5, Colors.PrimaryBlack));
var templateObject_1, templateObject_2, templateObject_3;
