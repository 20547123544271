import { __assign, __makeTemplateObject } from "tslib";
import styled from "styled-components";
export var H1 = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 700;\n  font-size: 36px;\n  line-height: 1.5;\n  color: ", ";\n  margin: 0;\n  display: inline-block;\n"], ["\n  font-weight: 700;\n  font-size: 36px;\n  line-height: 1.5;\n  color: ", ";\n  margin: 0;\n  display: inline-block;\n"])), function (_a) {
    var color = _a.color;
    return color || "inherit";
});
export var H2 = styled(H1).attrs(function (props) { return ({ as: "h2", ...props }); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 400;\n  font-size: 24px;\n"], ["\n  font-weight: 400;\n  font-size: 24px;\n"])));
export var H3 = styled(H1).attrs(function (props) { return ({ as: "h3", ...props }); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 20px;\n"], ["\n  font-size: 20px;\n"])));
export var H4 = styled(H1).attrs(function (props) { return ({ as: "h4", ...props }); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 16px;\n"], ["\n  font-size: 16px;\n"])));
export var H5 = styled(H1).attrs(function (props) { return ({ as: "h5", ...props }); })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 20px;\n"], ["\n  font-size: 14px;\n  line-height: 20px;\n"])));
export var H6 = styled(H1).attrs(function (props) { return ({ as: "h6", ...props }); })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 10px;\n  line-height: 16px;\n"], ["\n  font-size: 10px;\n  line-height: 16px;\n"])));
export var Body1 = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  line-height: 1.5;\n  font-weight: ", ";\n  color: ", ";\n"], ["\n  font-size: 16px;\n  line-height: 1.5;\n  font-weight: ", ";\n  color: ", ";\n"])), function (_a) {
    var light = _a.light;
    return (light ? 400 : 600);
}, function (_a) {
    var color = _a.color;
    return color || "inherit";
});
export var Body2 = styled(Body1)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 20px;\n"], ["\n  font-size: 14px;\n  line-height: 20px;\n"])));
export var Body3 = styled(Body1)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 12px;\n"], ["\n  font-size: 12px;\n"])));
export var Body4 = styled(Body1)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 10px;\n  line-height: 16px;\n"], ["\n  font-size: 10px;\n  line-height: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
