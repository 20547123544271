import { memo, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { getElementId } from "../../utils";
export var Portal = memo(function (_a) {
    var _id = _a.id, children = _a.children;
    var id = useRef(_id || getElementId("portal"));
    var el = useRef(document.getElementById(id.current) || document.createElement("div"));
    useEffect(function () {
        var element = el.current;
        if (!element.parentElement) {
            el.current.id = id.current;
            document.body.appendChild(el.current);
        }
        return function () {
            if (element.parentElement) {
                element.parentElement.removeChild(element);
            }
        };
    }, []);
    return createPortal(children, el.current);
});
