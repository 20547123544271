import { __makeTemplateObject, __read } from "tslib";
import React, { useRef, useState, useCallback, useMemo } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { OverflowMenuIcon } from "../../icons";
import { generateTestId } from "../../utils/generateTestId";
import { Button, ButtonKind } from "../Button";
import { Popover } from "../Popover";
import styled from "styled-components";
import { Colors } from "../../constants/colors";
export var OverflowMenu = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b, children = _a.children, _c = _a.placement, placement = _c === void 0 ? "bottom-start" : _c, name = _a.name;
    var _d = __read(useState(false), 2), isOpen = _d[0], setIsOpen = _d[1];
    var toggle = useCallback(function () {
        setIsOpen(function (state) { return !state; });
    }, []);
    var container = useRef(null);
    var referenceElement = useRef(null);
    var onClickOutside = useCallback(function () {
        setIsOpen(false);
    }, []);
    var clickReferences = useMemo(function () { return [container]; }, []);
    useOutsideClick(clickReferences, onClickOutside);
    return (React.createElement("div", { className: "overflow-menu-container " + className, ref: container },
        React.createElement(OverflowMenuButton, { className: "overflow-menu-button", ref: referenceElement, onClick: toggle, "data-testid": generateTestId("overflow-menu-toggle", name), type: "button", icon: React.createElement(OverflowMenuIcon, null) }),
        React.createElement(Popover, { reference: referenceElement, isOpen: isOpen, placement: placement, isLight: true },
            React.createElement(OverflowMenuContentContainer, null, children))));
};
var OverflowMenuButton = styled(Button).attrs({
    kind: ButtonKind.GHOST,
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  outline: none !important;\n  svg {\n    path {\n      fill: ", " !important;\n      stroke: ", " !important;\n    }\n  }\n  &:focus {\n    box-shadow: none;\n  }\n"], ["\n  outline: none !important;\n  svg {\n    path {\n      fill: ", " !important;\n      stroke: ", " !important;\n    }\n  }\n  &:focus {\n    box-shadow: none;\n  }\n"])), Colors.PrimaryBlack, Colors.PrimaryBlack);
var OverflowMenuContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  button {\n    text-align: start;\n    justify-content: start;\n    font-weight: 400;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  button {\n    text-align: start;\n    justify-content: start;\n    font-weight: 400;\n  }\n"])));
var templateObject_1, templateObject_2;
