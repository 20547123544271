import { useLayoutEffect, useRef } from "react";
export function useInnerRef(ref) {
    var innerRef = useRef(null);
    useLayoutEffect(function () {
        if (ref) {
            if (typeof ref === "function") {
                ref(innerRef.current);
            }
            else {
                ref.current = innerRef.current;
            }
        }
    }, [innerRef, ref]);
    return innerRef;
}
