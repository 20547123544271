import { __assign, __makeTemplateObject, __rest } from "tslib";
import React, { forwardRef, useRef, memo } from "react";
import { getElementId, populateTestId } from "../../../utils";
import { FormElement } from "../FormElement";
import styled from "styled-components";
import { StyledInput } from "../Input";
import { Colors } from "../../../constants/colors";
import { Body3 } from "../../Text";
import { useInnerRef } from "../../../hooks/useInnerRef";
export var TextArea = memo(forwardRef(function (_a, ref) {
    var children = _a.children, value = _a.value, placeholder = _a.placeholder, error = _a.error, id = _a.id, label = _a.label, _b = _a.isFormElement, isFormElement = _b === void 0 ? true : _b, maxLength = _a.maxLength, onChange = _a.onChange, className = _a.className, defaultValue = _a.defaultValue, rest = __rest(_a, ["children", "value", "placeholder", "error", "id", "label", "isFormElement", "maxLength", "onChange", "className", "defaultValue"]);
    var idRef = useRef(id || getElementId("input"));
    populateTestId(rest, rest.name, "input");
    var innerRef = useInnerRef(ref);
    var component = (React.createElement(React.Fragment, null,
        React.createElement(StyledTextArea, __assign({ placeholder: placeholder, "aria-label": placeholder, value: value, id: idRef.current, maxLength: maxLength, onChange: onChange }, rest, { ref: innerRef, error: error, defaultValue: defaultValue, className: isFormElement ? undefined : className }), children),
        maxLength && onChange && (React.createElement(CharacterCount, null, (value || defaultValue || "").toString().length + " / " + maxLength))));
    return isFormElement ? (React.createElement(FormElement, { id: idRef.current, label: label, error: typeof error === "string" ? error : undefined, errorMessageMarginTop: maxLength ? -18 : 0, className: className }, component)) : (component);
}));
var CharacterCount = styled(Body3)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: auto;\n  color: ", ";\n  margin-top: 4px;\n"], ["\n  margin-left: auto;\n  color: ", ";\n  margin-top: 4px;\n"])), Colors.Gray5);
var StyledTextArea = styled(StyledInput).attrs({
    as: "textarea",
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: auto;\n"], ["\n  height: auto;\n"])));
var templateObject_1, templateObject_2;
