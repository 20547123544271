import { useRef, useEffect } from "react";
export function useMountedCheck() {
    var isMounted = useRef(true);
    useEffect(function () {
        isMounted.current = true;
        return function () {
            isMounted.current = false;
        };
    });
    return isMounted;
}
