import { __makeTemplateObject } from "tslib";
import React from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import { TabNavLink } from "../TabLink";
import { Colors } from "../../../constants/colors";
export var TabLinkContainer = function (_a) {
    var tabs = _a.tabs, className = _a.className, headerTabs = _a.headerTabs;
    var rootPath = useRouteMatch().path;
    return (React.createElement(Container, { header: headerTabs, className: className }, tabs.map(function (_a) {
        var path = _a.path, content = _a.content;
        return (React.createElement(TabNavLink, { to: "" + rootPath + path, key: path, "$isSmall": !headerTabs }, content));
    })));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border-color: ", ";\n  border-style: solid;\n  border: 0px;\n  border-bottom: ", ";\n  justify-content: ", ";\n\n  & > * {\n    margin-right: 32px;\n\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n"], ["\n  display: flex;\n  border-color: ", ";\n  border-style: solid;\n  border: 0px;\n  border-bottom: ", ";\n  justify-content: ", ";\n\n  & > * {\n    margin-right: 32px;\n\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n"])), Colors.Gray3, function (props) { return (props.header ? "0px" : "1px"); }, function (props) { return (props.header ? "unset" : "center"); });
var templateObject_1;
