import { __makeTemplateObject } from "tslib";
import React, { useMemo } from "react";
import { DrawerHeader } from "./DrawerHeader";
import { DefaultFooter } from "./DefaultFooter";
import { Portal } from "../../Portal";
import styled from "styled-components";
import { OverlayContainer } from "../OverlayContainer";
import { useScrollLock } from "../useScrollLock";
export var Drawer = function (_a) {
    var children = _a.children, title = _a.title, description = _a.description, footer = _a.footer, onClickClose = _a.onClickClose, onClickSubmit = _a.onClickSubmit, submitText = _a.submitText, className = _a.className, _b = _a.name, name = _b === void 0 ? "" : _b, form = _a.form;
    footer = useMemo(function () {
        return footer || (React.createElement(DefaultFooter, { submitText: submitText, onClickClose: onClickClose, onClickSubmit: onClickSubmit, form: form }));
    }, [footer, onClickClose, onClickSubmit, submitText, form]);
    useScrollLock();
    return (React.createElement(Portal, null,
        React.createElement(OverlayContainer, null,
            React.createElement(DrawerContainer, { className: className, "data-testid": "drawer-container-" + name },
                React.createElement(DrawerHeader, { title: title, description: description, name: name, onClickClose: onClickClose }),
                React.createElement(DrawerBody, null, children),
                footer && React.createElement(DrawerFooter, null, footer)))));
};
var DrawerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  margin-left: auto;\n  background: white;\n  box-shadow: -20px 0px 30px rgba(18, 18, 18, 0.2);\n  height: 100%;\n  width: 592px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  position: relative;\n  margin-left: auto;\n  background: white;\n  box-shadow: -20px 0px 30px rgba(18, 18, 18, 0.2);\n  height: 100%;\n  width: 592px;\n  display: flex;\n  flex-direction: column;\n"])));
var DrawerBody = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  padding: 48px;\n  flex-direction: column;\n  flex-grow: 1;\n  overflow: auto;\n"], ["\n  display: flex;\n  padding: 48px;\n  flex-direction: column;\n  flex-grow: 1;\n  overflow: auto;\n"])));
var DrawerFooter = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 24px 48px 24px 48px;\n  background: white;\n  border: 1px solid #dfe1e6;\n  margin-top: auto;\n  display: flex;\n"], ["\n  padding: 24px 48px 24px 48px;\n  background: white;\n  border: 1px solid #dfe1e6;\n  margin-top: auto;\n  display: flex;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
