import { __makeTemplateObject } from "tslib";
import React from "react";
import { useAuth } from "@fireflyon/auth";
import { ReactComponent as LogoutIcon } from "./logout.svg";
import styled from "styled-components";
import { DropDownMenu } from "./DropdownMenu";
import { Colors } from "../../../constants";
export var UserProfile = function (_a) {
    var toggleProfile = _a.toggleProfile, isOpen = _a.isOpen;
    var _b = useAuth(), logout = _b.logout, user = _b.user;
    var userLogout = function () {
        logout({ returnTo: window.location.origin });
    };
    if (!user) {
        if (process.env.STORYBOOK_ENVIRONMENT)
            user = {
                picture: "avatar-placeholder.jpeg",
                name: "Hasan Ayan",
                email: "hasan@fireflyon.com",
            };
        else
            return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: "user-profile", onClick: toggleProfile },
        React.createElement(ProfileImage, { src: user === null || user === void 0 ? void 0 : user.picture, alt: "" }),
        isOpen && (React.createElement(ProfileMenu, null,
            React.createElement(ProfileMenuAvatar, { src: user === null || user === void 0 ? void 0 : user.picture, alt: "" }),
            React.createElement(ProfileMenuUserInfo, null,
                React.createElement("span", null, user === null || user === void 0 ? void 0 : user.name),
                React.createElement("span", null, user === null || user === void 0 ? void 0 : user.email)),
            React.createElement(ProfileMenuLogout, null,
                React.createElement("button", { onClick: userLogout, "data-testid": "user-profile-user-logout" },
                    React.createElement(LogoutIcon, { className: "logout-icon" }),
                    "Logout"))))));
};
var ProfileImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 50%;\n  height: 32px;\n  width: 32px;\n  vertical-align: middle;\n"], ["\n  border-radius: 50%;\n  height: 32px;\n  width: 32px;\n  vertical-align: middle;\n"])));
var ProfileMenu = styled(DropDownMenu)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  top: -13px;\n  box-shadow: 0 4px 19px 0 rgba(22, 33, 57, 0.15);\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n  text-align: center;\n  right: 20px;\n"], ["\n  top: -13px;\n  box-shadow: 0 4px 19px 0 rgba(22, 33, 57, 0.15);\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n  text-align: center;\n  right: 20px;\n"])));
var ProfileMenuAvatar = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 4rem;\n  height: 4rem;\n  display: inline-flex;\n  align-items: center;\n  margin: 2.5rem 7.4rem 0;\n  border-radius: 50%;\n"], ["\n  width: 4rem;\n  height: 4rem;\n  display: inline-flex;\n  align-items: center;\n  margin: 2.5rem 7.4rem 0;\n  border-radius: 50%;\n"])));
var ProfileMenuUserInfo = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  span:first-child {\n    font-weight: 700;\n    font-size: 1em;\n    margin: 0.75rem 0 0;\n    letter-spacing: 0.15px;\n    color: #121212;\n    display: block;\n  }\n\n  span:last-child {\n    font-weight: 400;\n    color: #4f4f4f;\n    font-size: 0.875em;\n    letter-spacing: 0.1px;\n    display: block;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  span:first-child {\n    font-weight: 700;\n    font-size: 1em;\n    margin: 0.75rem 0 0;\n    letter-spacing: 0.15px;\n    color: #121212;\n    display: block;\n  }\n\n  span:last-child {\n    font-weight: 400;\n    color: #4f4f4f;\n    font-size: 0.875em;\n    letter-spacing: 0.1px;\n    display: block;\n  }\n"])));
var ProfileMenuLogout = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 1rem 0 1rem 1.5rem;\n  margin: 1.45rem 0 0;\n  border-top: 2px solid #f5f5f5;\n  border-bottom: 2px solid #f5f5f5;\n  display: flex;\n\n  button {\n    color: ", ";\n    padding: 0;\n    background: transparent;\n    border: none;\n    margin-right: auto;\n    outline: none;\n    text-align: center;\n    display: flex;\n    line-height: 20px;\n\n    svg {\n      width: 20px;\n      height: 20px;\n      margin-right: 8px;\n      margin-top: auto;\n      margin-bottom: auto;\n    }\n\n    &:hover {\n      color: ", ";\n\n      svg {\n        path {\n          fill: ", ";\n        }\n      }\n    }\n  }\n"], ["\n  padding: 1rem 0 1rem 1.5rem;\n  margin: 1.45rem 0 0;\n  border-top: 2px solid #f5f5f5;\n  border-bottom: 2px solid #f5f5f5;\n  display: flex;\n\n  button {\n    color: ", ";\n    padding: 0;\n    background: transparent;\n    border: none;\n    margin-right: auto;\n    outline: none;\n    text-align: center;\n    display: flex;\n    line-height: 20px;\n\n    svg {\n      width: 20px;\n      height: 20px;\n      margin-right: 8px;\n      margin-top: auto;\n      margin-bottom: auto;\n    }\n\n    &:hover {\n      color: ", ";\n\n      svg {\n        path {\n          fill: ", ";\n        }\n      }\n    }\n  }\n"])), Colors.PrimaryBlack, Colors.PrimaryBlue, Colors.PrimaryBlue);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
