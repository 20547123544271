import React from "react";
import { useAuth } from "./AuthProvider";

export type BouncerProps = React.PropsWithChildren<{
  /**
   *
   *
   * WILL BE DEPRECATED SOON.
   * Makes sure the user has all of the roles passed before rendering the children content
   */
  roles?: string[];
  /**
   *
   *
   * Makes sure the user has all of the permissions passed before rendering the children content
   */
  requiredPermissions?: string[];
  /**
   *
   *
   * Makes sure the user has at least one of the permissions passed before rendering the children content.
   * if this prop is specified and the user has any of the permissions passed,
   * even if the authorization fails due to requiredPermissions check, the children content will be rendered
   */
  fallbackPermissions?: string[];
}>;

export const Bouncer: React.FC<BouncerProps> = ({
  roles,
  requiredPermissions,
  fallbackPermissions,
  children,
}) => {
  const { isAuthorized } = useAuth();

  let userIsAuthorized = isAuthorized(
    roles,
    requiredPermissions,
    fallbackPermissions
  );

  return userIsAuthorized ? <>{children}</> : <></>;
};
