import { __makeTemplateObject, __read } from "tslib";
import React, { useRef, useCallback, useState, useMemo } from "react";
import styled from "styled-components";
import { Popover } from "../../../components/Popover";
import { useOutsideClick } from "../../../hooks";
import { SecondaryMenu } from "../SecondaryMenu";
import { getElementId } from "../../../utils/elementIdGenerator";
import { useLocation } from "react-router-dom";
import { Link } from "../Link";
import { Colors } from "../../../constants";
export var MenuButton = function (_a) {
    var _b = _a.element, title = _b.title, path = _b.path, children = _b.children, primary = _a.primary, isCollapseMenu = _a.isCollapseMenu, _id = _a.id, onClickLink = _a.onClickLink;
    var idRef = useRef(_id || getElementId("navbar-menu"));
    var elementReference = useRef(null);
    var pathname = useLocation().pathname;
    var isActive = useMemo(function () {
        var hasMatchingChild = function (element) {
            return (element.children || []).some(function (child) {
                return child.path === pathname || (child ? hasMatchingChild(child) : false);
            });
        };
        return ((path && path === pathname) ||
            hasMatchingChild({ path: path, children: children, title: "" }));
    }, [pathname, path, children]);
    var _c = __read(useState(false), 2), isOpen = _c[0], setIsOpen = _c[1];
    var toggle = useCallback(function (e) {
        e.stopPropagation();
        if ((children || []).length > 0)
            setIsOpen(function (state) { return !state; });
        else
            onClickLink && onClickLink();
    }, [children, onClickLink]);
    var close = useCallback(function () { return setIsOpen(false); }, []);
    useOutsideClick(elementReference, close);
    var Wrapper = primary ? StyledLiPrimary : StyledLiSecondary;
    var onClickLinkFromChildMenu = useCallback(function () {
        setIsOpen(false);
        onClickLink && onClickLink();
    }, [onClickLink]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Wrapper, { ref: elementReference, onClick: toggle, id: idRef.current + "-button", active: primary && isActive, isCollapseMenu: isCollapseMenu },
            React.createElement(Link, { title: title, children: children, path: path, active: isActive, primary: primary }),
            children && (React.createElement(Popover, { isOpen: isOpen, reference: elementReference, notInPortal: true, placement: primary ? "bottom" : "right", offset: function (_a) {
                    var reference = _a.reference, popper = _a.popper;
                    if (!primary)
                        return [(popper.height - reference.height) / 2, 0];
                    else
                        return [-reference.width / 2 + popper.width / 2, -4];
                }, isLight: true },
                React.createElement(SecondaryMenu, { elements: children, id: idRef.current + "-content", onClickLink: onClickLinkFromChildMenu }))))));
};
var StyledLiPrimary = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  margin: 0 1rem;\n  padding: 18px 16px;\n  height: 100%;\n  display: inline-block;\n  align-content: center;\n  color: white;\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 18px;\n  letter-spacing: 0.1px;\n  font-family: inherit;\n\n  &:hover {\n    background: #1f1f1e;\n  }\n  svg {\n    margin-left: 8px;\n    margin-top: auto;\n    margin-bottom: auto;\n  }\n\n  button {\n    background: transparent;\n    color: white;\n    border: none;\n    outline: none;\n    font-family: inherit;\n    font-weight: inherit;\n    cursor: pointer;\n  }\n\n  ", "\n"], ["\n  position: relative;\n  margin: 0 1rem;\n  padding: 18px 16px;\n  height: 100%;\n  display: inline-block;\n  align-content: center;\n  color: white;\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 18px;\n  letter-spacing: 0.1px;\n  font-family: inherit;\n\n  &:hover {\n    background: #1f1f1e;\n  }\n  svg {\n    margin-left: 8px;\n    margin-top: auto;\n    margin-bottom: auto;\n  }\n\n  button {\n    background: transparent;\n    color: white;\n    border: none;\n    outline: none;\n    font-family: inherit;\n    font-weight: inherit;\n    cursor: pointer;\n  }\n\n  ",
    "\n"])), function (props) {
    return !props.isCollapseMenu &&
        props.active &&
        "\n    :before{\n    content: \"\";\n    display: block;\n    background: #fff;\n    height: 4px;\n    position: absolute;\n    left:0;\n    right:0;\n    bottom: 0;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n  }\n  ";
});
var StyledLiSecondary = styled(StyledLiPrimary)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0;\n  min-width: 208px;\n  padding: 0;\n  align-items: start;\n\n  a {\n    flex-grow: 1;\n\n    &:hover {\n      text-decoration: none;\n    }\n  }\n  button {\n    width: 100%;\n    background: transparent;\n    border: none;\n    outline: none;\n    margin: 0;\n    padding: 8px 16px;\n    border-radius: 4px;\n    line-height: 32px;\n    color: black;\n    font-weight: 400;\n    font-size: 14px;\n    font-family: inherit;\n    display: flex;\n    flex-grow: 1;\n\n    &:hover {\n      background: ", ";\n      color: white;\n\n      svg {\n        path {\n          fill: white;\n        }\n      }\n    }\n  }\n\n  &:hover {\n    background: inherit;\n  }\n"], ["\n  margin: 0;\n  min-width: 208px;\n  padding: 0;\n  align-items: start;\n\n  a {\n    flex-grow: 1;\n\n    &:hover {\n      text-decoration: none;\n    }\n  }\n  button {\n    width: 100%;\n    background: transparent;\n    border: none;\n    outline: none;\n    margin: 0;\n    padding: 8px 16px;\n    border-radius: 4px;\n    line-height: 32px;\n    color: black;\n    font-weight: 400;\n    font-size: 14px;\n    font-family: inherit;\n    display: flex;\n    flex-grow: 1;\n\n    &:hover {\n      background: ", ";\n      color: white;\n\n      svg {\n        path {\n          fill: white;\n        }\n      }\n    }\n  }\n\n  &:hover {\n    background: inherit;\n  }\n"])), Colors.PrimaryBlue);
var templateObject_1, templateObject_2;
