import { __makeTemplateObject } from "tslib";
import React from "react";
import styled from "styled-components";
import { MenuButton } from "../MenuButton";
export var SecondaryMenu = function (_a) {
    var elements = _a.elements, className = _a.className, onClickLink = _a.onClickLink, id = _a.id;
    return (React.createElement(StyledUl, { className: className, id: id }, elements.map(function (element) { return (React.createElement(MenuButton, { element: element, key: element.title, id: "collapse-" + element.title.replace(/ /g, "").toLowerCase(), onClickLink: onClickLink })); })));
};
var StyledUl = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: 0;\n  margin-left: 72px;\n  height: 100%;\n  flex-grow: 1;\n  margin: 0;\n  flex-basis: auto;\n  flex-direction: column;\n  width: max-content;\n\n  .d-none {\n    display: none !important;\n  }\n"], ["\n  display: flex;\n  padding: 0;\n  margin-left: 72px;\n  height: 100%;\n  flex-grow: 1;\n  margin: 0;\n  flex-basis: auto;\n  flex-direction: column;\n  width: max-content;\n\n  .d-none {\n    display: none !important;\n  }\n"])));
var templateObject_1;
