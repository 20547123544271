import { __makeTemplateObject, __read, __spread } from "tslib";
import React, { memo, useCallback } from "react";
import { CheckBox } from "../CheckBox";
import { isEqual } from "../../../utils";
import { FormElement } from "../FormElement";
import styled from "styled-components";
export var CheckBoxGroup = memo(function (_a) {
    var options = _a.options, onChange = _a.onChange, groupName = _a.groupName, _b = _a.value, val = _b === void 0 ? [] : _b, label = _a.label, _c = _a.isFormElement, isFormElement = _c === void 0 ? true : _c, className = _a.className, error = _a.error;
    var _onChange = useCallback(function (checked, value) {
        var result = __spread(val);
        if (checked)
            result.push(value);
        else
            result = result.filter(function (item) { return item !== value; });
        !isEqual(val, result, true) && onChange && onChange(result);
    }, [onChange, val]);
    var component = (React.createElement(CheckBoxGroupContainer, { className: className }, options.map(function (_a) {
        var label = _a.label, value = _a.value, className = _a.className;
        return (React.createElement(CheckBoxContainer, { key: value, className: className },
            React.createElement(CheckBox, { label: label, name: groupName + "-" + value.toString(), checked: onChange && val.includes(value), onChange: onChange
                    ? function (e) {
                        _onChange(e.target.checked, value);
                    }
                    : undefined })));
    })));
    return isFormElement ? (React.createElement(FormElement, { label: label, error: error }, component)) : (component);
}, isEqual);
var CheckBoxGroupContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var CheckBoxContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-grow: 1;\n  flex-basis: 0;\n"], ["\n  flex-grow: 1;\n  flex-basis: 0;\n"])));
var templateObject_1, templateObject_2;
