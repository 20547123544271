import { __assign, __makeTemplateObject, __read } from "tslib";
import React, { useState, memo, useRef, useMemo, useCallback } from "react";
import { Notification } from "./Notification";
import { UserProfile } from "./UserProfile";
import { Logo } from "./Logo";
import { Settings } from "./Settings";
import { useOutsideClick } from "../../hooks";
import styled from "styled-components";
import { Colors } from "../../constants";
import { PrimaryMenu } from "./PrimaryMenu";
import { useAuth, PermissionCheckType } from "@fireflyon/auth";
var _Navbar = memo(function (_a) {
    var navigationItems = _a.navigationItems, className = _a.className, realm = _a.realm;
    var _b = __read(useState(""), 2), openItem = _b[0], setOpenItem = _b[1];
    var toggleDropDown = function (title) {
        setOpenItem(title === openItem ? "" : title);
    };
    var navRef = useRef(null);
    useOutsideClick(navRef, function () { return toggleDropDown(""); });
    var userHasPermissions = useAuth().userHasPermissions;
    var isMenuElementPermitted = useCallback(function (element) {
        if (element.permissions) {
            return userHasPermissions(element.permissions, PermissionCheckType.ALL);
        }
        else if (element.children && element.children.length > 0) {
            return element.children.some(isMenuElementPermitted);
        }
        else if (!element.path) {
            return false;
        }
        else {
            return true;
        }
    }, [userHasPermissions]);
    var filterMenuElements = useCallback(function (elements) {
        var filteredElements = [];
        elements.forEach(function (element) {
            var newElement = __assign({}, element);
            if (newElement.children)
                newElement.children = filterMenuElements(newElement.children);
            if (isMenuElementPermitted(newElement))
                filteredElements.push(newElement);
        });
        return filteredElements;
    }, [isMenuElementPermitted]);
    var filteredMenuElements = useMemo(function () { return filterMenuElements(navigationItems); }, [navigationItems, filterMenuElements]);
    return (React.createElement("nav", { ref: navRef, className: className },
        React.createElement(Logo, { realm: realm }),
        React.createElement(PrimaryMenu, { elements: filteredMenuElements }),
        React.createElement(RightMenu, null,
            React.createElement(Notification, null),
            React.createElement(Settings, null),
            React.createElement(UserProfile, { isOpen: openItem === "user-profile", toggleProfile: function () { return setOpenItem("user-profile"); } }))));
});
export var Navbar = styled(_Navbar)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  display: flex;\n  flex-flow: row nowrap;\n  -ms-flex-pack: start;\n  justify-content: flex-start;\n  align-items: center;\n  position: fixed;\n  padding: 0 30px;\n  font-family: inherit;\n  flex-shrink: 0;\n  z-index: 990;\n  min-height: 56px;\n  top: 0;\n  left: 0;\n  right: 0;\n"], ["\n  background: ", ";\n  display: flex;\n  flex-flow: row nowrap;\n  -ms-flex-pack: start;\n  justify-content: flex-start;\n  align-items: center;\n  position: fixed;\n  padding: 0 30px;\n  font-family: inherit;\n  flex-shrink: 0;\n  z-index: 990;\n  min-height: 56px;\n  top: 0;\n  left: 0;\n  right: 0;\n"])), Colors.PrimaryBlack);
var RightMenu = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  & > * {\n    margin: 0 16px;\n    margin-top: auto;\n    margin-bottom: auto;\n  }\n\n  & > *:first-child {\n    margin-left: 0;\n  }\n  & > *:last-child {\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n  & > * {\n    margin: 0 16px;\n    margin-top: auto;\n    margin-bottom: auto;\n  }\n\n  & > *:first-child {\n    margin-left: 0;\n  }\n  & > *:last-child {\n    margin-right: 0;\n  }\n"])));
var templateObject_1, templateObject_2;
