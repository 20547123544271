import { __assign, __rest } from "tslib";
import React, { useMemo } from "react";
import { Tooltip } from "./BaseTooltip";
import { InformationIcon, InformationIconWhite } from "../../icons";
export var InfoTooltip = function (_a) {
    var children = _a.children, isDark = _a.isDark, _b = _a.isSmall, isSmall = _b === void 0 ? true : _b, props = __rest(_a, ["children", "isDark", "isSmall"]);
    var referenceElement = useMemo(function () {
        var Icon = isDark ? InformationIcon : InformationIconWhite;
        return React.createElement(Icon, { width: 16, height: 16 });
    }, [isDark]);
    return (React.createElement(Tooltip, __assign({ referenceElement: referenceElement }, props, { isSmall: isSmall }), children));
};
