import { __makeTemplateObject } from "tslib";
import React from "react";
import { CloseIcon } from "../../../../icons";
import { generateTestId } from "../../../../utils/generateTestId";
import { H3, Body2 } from "../../../Text";
import styled from "styled-components";
import { Colors } from "../../../../constants";
export var DrawerHeader = function (_a) {
    var title = _a.title, description = _a.description, name = _a.name, onClickClose = _a.onClickClose;
    return (React.createElement(DrawerHeaderContainer, null,
        React.createElement("div", null,
            React.createElement(H3, null, title),
            description && React.createElement(Body2, { light: true }, description)),
        React.createElement(CloseIcon, { className: "pointer-clickable", "data-testid": generateTestId("drawer-close", name), onClick: onClickClose })));
};
var DrawerHeaderContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  background: ", ";\n  padding: 32px 48px 32px 48px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  background: ", ";\n  padding: 32px 48px 32px 48px;\n"])), Colors.Gray1);
var templateObject_1;
