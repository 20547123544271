import React, { useState, useEffect, useContext } from "react";
import createAuth0Client, {
  Auth0ClientOptions,
  getIdTokenClaimsOptions,
  IdToken,
  RedirectLoginOptions,
  GetTokenWithPopupOptions,
  PopupConfigOptions,
  LogoutOptions,
} from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { defaultDomain } from "./constants.json";
import { useHistory } from "react-router";

type Props = Partial<Auth0ClientOptions> & {
  client_id: string;
  ignoreCacheOnRefresh?: boolean;
};

export type User = {
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  locale: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
  "https://fireflyon.com/roles": string[];
  "https://fireflyon.com/permissions": string[];
};

export enum PermissionCheckType {
  ALL = 1,
  ANY = 2,
}
interface Auth0ContextType {
  isAuthenticated: boolean;
  user?: User;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: () => Promise<void>;
  handleRedirectCallback: () => Promise<void>;
  getIdTokenClaims: (options?: getIdTokenClaimsOptions) => Promise<IdToken>;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  getTokenSilently: (ignoreCache?: boolean) => Promise<any>;
  getTokenWithPopup: (
    options?: GetTokenWithPopupOptions,
    config?: PopupConfigOptions
  ) => Promise<string>;
  logout: (options?: LogoutOptions) => void;
  userHasRole: (roles: string[]) => boolean;
  userHasPermissions: (
    permissions: string[],
    mode?: PermissionCheckType
  ) => boolean;
  isAuthorized: (
    roles?: string[],
    requiredPermissions?: string[],
    fallbackPermissions?: string[]
  ) => boolean;
}

interface State {
  isAuthenticated: boolean;
  user?: User;
  auth0Client?: Auth0Client;
  loading: boolean;
  popupOpen: boolean;
}

export type AppState = {
  pathname: string;
  expiresOn: number;
};

export const AuthContext = React.createContext<Auth0ContextType>({
  isAuthenticated: false,
  loading: false,
  popupOpen: false,
  loginWithPopup: async () => undefined,
  handleRedirectCallback: async () => undefined,
  getIdTokenClaims: async () => ({ __raw: "" }),
  loginWithRedirect: async () => undefined,
  getTokenSilently: async () => undefined,
  getTokenWithPopup: async () => "",
  logout: async () => "",
  userHasRole: () => false,
  userHasPermissions: () => false,
  isAuthorized: () => false,
});

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ignoreCacheOnRefresh = false,
  domain = defaultDomain,
  redirect_uri = window.location.origin,
  scope = "openid profile email",
  ...initOptions
}) => {
  const [state, setState] = useState<State>({
    isAuthenticated: false,
    loading: true,
    popupOpen: false,
  });

  if (!scope.includes("openid")) scope += " openid";
  if (!scope.includes("profile")) scope += " profile";
  if (!scope.includes("email")) scope += " email";
  scope = scope.trim();

  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    history.push(
      appState && appState.pathname
        ? appState.pathname
        : window.location.pathname
    );
  };

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        ...initOptions,
        scope,
        cacheLocation: "localstorage",
        redirect_uri,
        domain,
      });
      setState((state) => ({
        ...state,
        auth0Client: auth0FromHook,
      }));

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setState((state) => ({ ...state, isAuthenticated }));

      if (isAuthenticated) {
        if (ignoreCacheOnRefresh && process.env.NODE_ENV !== "development") {
          await auth0FromHook.getTokenSilently({
            ignoreCache: true,
            scope,
            audience: initOptions.audience || "",
          });
        }

        const user = await auth0FromHook.getUser();

        setState((state) => ({ ...state, user }));
      }

      setState((state) => ({ ...state, loading: false }));
    };
    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setState((state) => ({ ...state, popupOpen: true }));
    try {
      await state.auth0Client!.loginWithPopup(params);
    } catch (error) {
    } finally {
      setState((state) => ({ ...state, popupOpen: false }));
    }
    const user = await state.auth0Client!.getUser();
    setState((state) => ({ ...state, user, isAuthenticated: true }));
  };

  const handleRedirectCallback = async () => {
    setState((state) => ({ ...state, loading: true }));
    await state.auth0Client!.handleRedirectCallback();
    const user = await state.auth0Client!.getUser();
    setState((state) => ({
      ...state,
      loading: false,
      isAuthenticated: true,
      user: user,
    }));
  };

  const userHasPermissions = (
    permissions: string[],
    permissionCheckType: PermissionCheckType = PermissionCheckType.ALL
  ): boolean => {
    if (state.loading) return false;
    if (!state.isAuthenticated) return false;
    if (permissions.length === 0) return false;

    const userPermissions = state.user?.[
      "https://fireflyon.com/permissions"
    ] as string[];

    if (userPermissions.includes("full_access")) return true;

    if (permissionCheckType === PermissionCheckType.ALL) {
      return !permissions.some(
        (permission) => !userPermissions.includes(permission)
      );
    } else if (permissionCheckType === PermissionCheckType.ANY) {
      return permissions.some((permission) =>
        userPermissions.includes(permission)
      );
    }

    return (
      userPermissions.includes("full_access") ||
      !permissions.some((permission) => !userPermissions.includes(permission))
    );
  };

  const userHasRole = (roles: string[]): boolean => {
    if (state.loading) return false;
    if (!state.isAuthenticated) return false;
    if (roles.length === 0) return false;

    const userRoles = state.user?.["https://fireflyon.com/roles"] as string[];
    return (
      userRoles.includes("full_access") ||
      roles.some((role) => userRoles.includes(role))
    );
  };

  const isAuthorized = (
    roles?: string[],
    requiredPermissions?: string[],
    fallbackPermissions?: string[]
  ) => {
    let isAuthorized = false;

    if (!roles && !requiredPermissions && !fallbackPermissions) return true;

    if (roles && userHasRole(roles)) {
      isAuthorized = true;
    }

    if (requiredPermissions) {
      if (userHasPermissions(requiredPermissions, PermissionCheckType.ALL))
        isAuthorized = true;
      else isAuthorized = false;
    }

    if (
      fallbackPermissions &&
      userHasPermissions(fallbackPermissions, PermissionCheckType.ANY)
    )
      isAuthorized = true;

    return isAuthorized;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        loading: state.loading,
        popupOpen: state.popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => state.auth0Client!.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => state.auth0Client!.loginWithRedirect(...p),
        getTokenSilently: (ignoreCache: boolean = false) =>
          state.auth0Client!.getTokenSilently({
            ignoreCache,
            scope,
            audience: initOptions.audience || "",
          }),
        getTokenWithPopup: (...p) => state.auth0Client!.getTokenWithPopup(...p),
        logout: (options?: LogoutOptions) => {
          const params = { returnTo: window.location.origin, ...options };
          state.auth0Client!.logout(params);
        },
        userHasRole,
        userHasPermissions,
        isAuthorized,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
