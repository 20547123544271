import { __makeTemplateObject } from "tslib";
import React, { useRef, useCallback, useLayoutEffect } from "react";
import styled from "styled-components";
import { MenuButton } from "../MenuButton";
import ResizeObserver from "resize-observer-polyfill";
export var PrimaryMenu = function (_a) {
    var elements = _a.elements, className = _a.className;
    var containerRef = useRef(null);
    var compute = useCallback(function () {
        var primaryMenu = containerRef.current;
        var collapseMenuContainer = document.getElementById("collapse-menu-button");
        if (!primaryMenu || !collapseMenuContainer)
            return;
        var collapseMenu = document.getElementById("collapse-menu-content");
        if (primaryMenu.clientHeight > 56) {
            collapseMenuContainer.classList.remove("d-none");
            while (primaryMenu.clientHeight > 56) {
                var visiblePrimaryMenuElements = primaryMenu.querySelectorAll(":scope > li:not(.d-none):not(#collapse-menu-button)");
                if (visiblePrimaryMenuElements.length === 0)
                    return;
                var lastVisiblePrimaryMenuElement = visiblePrimaryMenuElements.item(visiblePrimaryMenuElements.length - 1);
                lastVisiblePrimaryMenuElement.classList.add("d-none");
                var collapseMenuButton = document.getElementById("collapse-" + lastVisiblePrimaryMenuElement.id);
                collapseMenuButton === null || collapseMenuButton === void 0 ? void 0 : collapseMenuButton.classList.remove("d-none");
            }
        }
        else {
            var firstInvisiblePrimaryMenuElement = primaryMenu.querySelector(":scope > li.d-none");
            if (firstInvisiblePrimaryMenuElement) {
                firstInvisiblePrimaryMenuElement.classList.remove("d-none");
                var collapseMenuButton = document.getElementById("collapse-" + firstInvisiblePrimaryMenuElement.id);
                collapseMenuButton === null || collapseMenuButton === void 0 ? void 0 : collapseMenuButton.classList.add("d-none");
            }
        }
        var invisibleCollapseMenuElements = collapseMenu.querySelectorAll(":scope > .d-none");
        if (invisibleCollapseMenuElements.length === elements.length) {
            collapseMenuContainer.classList.add("d-none");
        }
        if (primaryMenu.clientHeight > 56) {
            compute();
        }
        else if (primaryMenu.clientHeight === 56) {
            window.dispatchEvent(new Event("resize"));
        }
    }, [elements]);
    useLayoutEffect(function () {
        var resizeObserver = new ResizeObserver(function () {
            compute();
        });
        var primaryMenu = document.getElementById("primary-menu-container");
        resizeObserver.observe(primaryMenu);
        var collapseMenu = document.getElementById("collapse-menu-content");
        collapseMenu.childNodes.forEach(function (child) {
            return child.classList.add("d-none");
        });
        compute();
        return function () {
            resizeObserver.unobserve(primaryMenu);
        };
    }, [compute]);
    return (React.createElement(StyledUl, { className: className, ref: containerRef, id: "primary-menu-container" },
        elements.map(function (element) { return (React.createElement(MenuButton, { element: element, key: element.title, primary: true, id: element.title.replace(/ /g, "").toLowerCase() })); }),
        React.createElement(MenuButton, { element: { title: "More", children: elements }, id: "collapse-menu", isCollapseMenu: true, primary: true })));
};
var StyledUl = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: 0;\n  margin-left: 72px;\n  height: 100%;\n  flex-grow: 1;\n  margin: 0;\n  flex-basis: auto;\n\n  .d-none {\n    display: none !important;\n  }\n"], ["\n  display: flex;\n  padding: 0;\n  margin-left: 72px;\n  height: 100%;\n  flex-grow: 1;\n  margin: 0;\n  flex-basis: auto;\n\n  .d-none {\n    display: none !important;\n  }\n"])));
var templateObject_1;
