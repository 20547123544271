import { __assign, __makeTemplateObject, __rest } from "tslib";
import styled from "styled-components";
import { tint } from "polished";
import { Colors } from "../../constants/colors";
import React from "react";
import { H6 } from "../Text";
export var BadgePill = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: bold;\n  font-size: 10px;\n  line-height: 16px;\n  letter-spacing: 0.1px;\n  color: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  display: inline-block;\n  text-align: center;\n  white-space: nowrap;\n  vertical-align: baseline;\n"], ["\n  font-weight: bold;\n  font-size: 10px;\n  line-height: 16px;\n  letter-spacing: 0.1px;\n  color: ", ";\n  background-color: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  display: inline-block;\n  text-align: center;\n  white-space: nowrap;\n  vertical-align: baseline;\n"])), function (props) { return props.color; }, function (props) { return tint(0.8, props.color); }, function (props) { return (props.small ? "8px" : "12px"); }, function (props) { return (props.small ? "0 8px" : "4px 12px"); });
var BadgeCircleWrapper = styled.span.attrs(function (props) { return ({
    color: props.color || Colors.PrimaryBlack,
    backgroundColor: props.backgroundColor || Colors.Gray3,
}); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  background-color: ", ";\n  border-radius: 100%;\n  vertical-align: middle;\n  text-align: center;\n  width: 32px;\n  height: 32px;\n  display: inline-flex;\n  & > * {\n    margin: auto;\n  }\n"], ["\n  color: ", ";\n  background-color: ", ";\n  border-radius: 100%;\n  vertical-align: middle;\n  text-align: center;\n  width: 32px;\n  height: 32px;\n  display: inline-flex;\n  & > * {\n    margin: auto;\n  }\n"])), function (props) { return props.color; }, function (props) { return props.backgroundColor; });
export var BadgeCircle = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BadgeCircleWrapper, __assign({}, props),
        React.createElement(H6, null, children)));
};
export var BadgeSquared = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  border-radius: 4px;\n  color: ", ";\n  background-color: ", ";\n  font-size: 10px;\n  line-height: 16px;\n  border-radius: 4px;\n  padding: 4px 8px;\n  font-weight: bold;\n"], ["\n  display: inline-block;\n  border-radius: 4px;\n  color: ", ";\n  background-color: ", ";\n  font-size: 10px;\n  line-height: 16px;\n  border-radius: 4px;\n  padding: 4px 8px;\n  font-weight: bold;\n"])), function (props) { return props.color; }, function (props) { return tint(0.8, props.color || Colors.Gray5); });
var templateObject_1, templateObject_2, templateObject_3;
