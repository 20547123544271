import { __makeTemplateObject } from "tslib";
import React from "react";
import styled from "styled-components";
import { ButtonKind, Button } from "../../Button";
export var DefaultModalFooter = function (_a) {
    var rightButtonColor = _a.rightButtonColor, rightButtonKind = _a.rightButtonKind, rightButtonText = _a.rightButtonText, leftButtonColor = _a.leftButtonColor, _b = _a.leftButtonKind, leftButtonKind = _b === void 0 ? ButtonKind.SECONDARY : _b, leftButtonText = _a.leftButtonText, onClickLeftButton = _a.onClickLeftButton, onClickRightButton = _a.onClickRightButton;
    return (React.createElement(React.Fragment, null,
        onClickLeftButton && (React.createElement(LeftButton, { kind: leftButtonKind, color: leftButtonColor, onClick: onClickLeftButton }, leftButtonText)),
        onClickRightButton && (React.createElement(RightButton, { kind: rightButtonKind, color: rightButtonColor, onClick: onClickRightButton }, rightButtonText))));
};
var LeftButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: auto;\n"], ["\n  margin-right: auto;\n"])));
var RightButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: auto;\n"], ["\n  margin-left: auto;\n"])));
var templateObject_1, templateObject_2;
