import { __read } from "tslib";
import { useState, useCallback, useEffect, useLayoutEffect, } from "react";
import { debounce } from "debounce";
function checkVisibility(el, partial) {
    if (!el) {
        return false;
    }
    var _a = el.getBoundingClientRect(), top = _a.top, right = _a.right, bottom = _a.bottom, left = _a.left, width = _a.width, height = _a.height;
    if (top + right + bottom + left === 0) {
        return false;
    }
    var topCheck = partial ? top + height : top;
    var bottomCheck = partial ? bottom - height : bottom;
    var rightCheck = partial ? right - width : right;
    var leftCheck = partial ? left + width : left;
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;
    return (topCheck >= 0 &&
        leftCheck >= 0 &&
        bottomCheck <= windowHeight &&
        rightCheck <= windowWidth);
}
export var useVisibility = function (element, entireElement, recalculate) {
    var _a = __read(useState(false), 2), inView = _a[0], setInView = _a[1];
    var calculate = useCallback(debounce(function () {
        var _inView = element.current && checkVisibility(element.current, !entireElement);
        if (_inView && !recalculate)
            window.removeEventListener("scroll", calculate, true);
        if (_inView !== inView)
            setInView(!!_inView);
    }, 150), [inView, element, entireElement, recalculate]);
    useLayoutEffect(function () {
        calculate();
    }, [calculate]);
    useEffect(function () {
        window.addEventListener("scroll", calculate, true);
        return function () { return window.removeEventListener("scroll", calculate, true); };
    }, [calculate]);
    return inView;
};
