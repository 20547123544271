import { __makeTemplateObject, __read } from "tslib";
import React from "react";
import styled from "styled-components";
import { useLottie, Lottie } from "react-lottie-hook";
import { spinnerAnimation } from "../../animations";
var StyledLottie = styled(Lottie)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: auto !important;\n"], ["\n  margin: auto !important;\n"])));
var _Spinner = function (_a) {
    var height = _a.height, width = _a.width, className = _a.className;
    var _b = __read(useLottie({
        renderer: "svg",
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            progressiveLoad: true,
        },
        animationData: spinnerAnimation,
    }), 1), lottieRef = _b[0];
    return (React.createElement("div", { className: className },
        React.createElement(StyledLottie, { lottieRef: lottieRef, height: height, width: width })));
};
export var Spinner = styled(_Spinner)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  position: ", ";\n  top: 0;\n  left: 0;\n  width: ", ";\n  height: ", ";\n  z-index: 99999;\n  background: ", ";\n"], ["\n  display: flex;\n  justify-content: center;\n  position: ", ";\n  top: 0;\n  left: 0;\n  width: ", ";\n  height: ", ";\n  z-index: 99999;\n  background: ", ";\n"])), function (props) { return (props.inline ? "relative" : "absolute"); }, function (props) { return (props.inline ? "16px" : "100%"); }, function (props) { return (props.inline ? "16px" : "100%"); }, function (props) { return (props.blue ? "white" : "unset"); });
var templateObject_1, templateObject_2;
