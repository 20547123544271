var _a;
import { __makeTemplateObject } from "tslib";
import React, { useMemo, useLayoutEffect, useRef, memo } from "react";
import styled from "styled-components";
import { ErrorIcon, WarningIcon, InformationIcon, SuccessIcon, CloseIcon, } from "../../icons";
import { H4, Body2 } from "../Text";
import { Colors } from "../../constants";
import { tint } from "polished";
export var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["ERROR"] = 0] = "ERROR";
    NotificationType[NotificationType["WARNING"] = 1] = "WARNING";
    NotificationType[NotificationType["SUCCESS"] = 2] = "SUCCESS";
    NotificationType[NotificationType["INFORMATION"] = 3] = "INFORMATION";
})(NotificationType || (NotificationType = {}));
var colorMap = (_a = {},
    _a[NotificationType.ERROR] = tint(0.8, Colors.Red),
    _a[NotificationType.INFORMATION] = tint(0.8, Colors.Cyan),
    _a[NotificationType.SUCCESS] = tint(0.8, Colors.Green),
    _a[NotificationType.WARNING] = tint(0.8, Colors.Yellow),
    _a);
export var NotificationMessage = memo(function (_a) {
    var title = _a.title, message = _a.message, _b = _a.type, type = _b === void 0 ? NotificationType.WARNING : _b, actionMessage = _a.actionMessage, onClickAction = _a.onClickAction, onDismiss = _a.onDismiss, isToastMessage = _a.isToastMessage, className = _a.className, _c = _a.dismissible, dismissible = _c === void 0 ? true : _c, focusOnMount = _a.focusOnMount;
    var Icon = useMemo(function () {
        switch (type) {
            case NotificationType.ERROR:
                return ErrorIcon;
            case NotificationType.WARNING:
                return WarningIcon;
            case NotificationType.INFORMATION:
                return InformationIcon;
            case NotificationType.SUCCESS:
                return SuccessIcon;
        }
    }, [type]);
    var hasAction = actionMessage && onClickAction;
    var color = useMemo(function () { return colorMap[type]; }, [type]);
    var headerContent = (React.createElement(React.Fragment, null,
        title && React.createElement(Title, null, title),
        hasAction && React.createElement(Action, { onClick: onClickAction }, actionMessage),
        !hasAction && !isToastMessage && message && (React.createElement(Message, null, message))));
    var containerRef = useRef(null);
    useLayoutEffect(function () {
        var _a;
        if (focusOnMount)
            (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [focusOnMount]);
    return (React.createElement(NotificationContainer, { ref: containerRef, color: color, className: className, isToastContainer: isToastMessage, tabIndex: -1 },
        React.createElement(IconContainer, null,
            React.createElement(Icon, { width: 24, height: 24 })),
        React.createElement(CenterContainer, null,
            React.createElement(HeaderContainer, null, headerContent),
            message && (hasAction || isToastMessage) && (React.createElement(Message, null, message))),
        dismissible && React.createElement(StyledCloseIcon, { onClick: onDismiss })));
});
var IconContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-shrink: 0;\n"], ["\n  flex-shrink: 0;\n"])));
var CenterContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 16px;\n  flex-grow: 1;\n"], ["\n  margin-left: 16px;\n  flex-grow: 1;\n"])));
var HeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  white-space: nowrap;\n  display: flex;\n"], ["\n  white-space: nowrap;\n  display: flex;\n"])));
var Message = styled(Body2).attrs({ light: true })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  line-height: 24px;\n  width: 100%;\n  white-space: normal;\n"], ["\n  line-height: 24px;\n  width: 100%;\n  white-space: normal;\n"])));
var NotificationContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  padding: 16px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: ", ";\n  width: ", ";\n  &:focus {\n    border: inherit;\n    outline: inherit;\n  }\n"], ["\n  display: flex;\n  padding: 16px;\n  background: ", ";\n  box-shadow: ",
    ";\n  border-radius: ", ";\n  width: ", ";\n  &:focus {\n    border: inherit;\n    outline: inherit;\n  }\n"])), function (props) { return props.color || "white"; }, function (props) {
    return props.isToastContainer ? "0px 2px 8px rgba(12, 12, 13, 0.1)" : "unset";
}, function (props) { return (props.isToastContainer ? "4px" : "unset"); }, function (props) { return (props.isToastContainer ? "322px" : "unset"); });
var Action = styled(Body2)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n  flex-grow: 1;\n  color: ", ";\n  line-height: 24px;\n"], ["\n  cursor: pointer;\n  flex-grow: 1;\n  color: ", ";\n  line-height: 24px;\n"])), Colors.Blue);
var StyledCloseIcon = styled(CloseIcon)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-left: auto;\n  cursor: pointer;\n  flex-shrink: 0;\n"], ["\n  margin-left: auto;\n  cursor: pointer;\n  flex-shrink: 0;\n"])));
var Title = styled(H4)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-right: 16px;\n"], ["\n  margin-right: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
