import { __assign, __makeTemplateObject, __read, __spread } from "tslib";
import React, { createContext, useState, useCallback, useContext, memo, useEffect, useMemo, } from "react";
import styled from "styled-components";
import { NotificationMessage, } from "./NotificationMessage";
import { useMountedCheck } from "../../hooks";
var usedNotificationIds = [];
var getNotificationId = function () {
    var randomNumber = 0;
    while (randomNumber !== 0 && !usedNotificationIds.includes(randomNumber))
        randomNumber = Math.floor(Math.random() * 1000);
    usedNotificationIds.push(randomNumber);
    return randomNumber.toString();
};
export var NotificationContext = createContext({
    notifications: [],
    push: function () { return undefined; },
    dismiss: function () { return undefined; },
    clear: function () { return undefined; },
});
var NotificationListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  & > * {\n    margin-bottom: ", ";\n  }\n"], ["\n  margin-bottom: ", ";\n  & > * {\n    margin-bottom: ", ";\n  }\n"])), function (props) { return (props.hasChildren ? "-16px" : ""); }, function (props) { return (props.hasChildren ? "16px" : ""); });
export var NotificationProvider = memo(function (_a) {
    var children = _a.children;
    var _b = __read(useState([]), 2), notifications = _b[0], setNotifications = _b[1];
    var isMounted = useMountedCheck();
    var dismiss = useCallback(function (id) {
        setNotifications(function (notifications) {
            var notification = notifications.find(function (_notification) { return _notification.id === id; });
            if (!notification)
                return notifications;
            notification.onDismiss && notification.onDismiss();
            return notifications.filter(function (_notification) { return _notification !== notification; });
        });
    }, []);
    var push = useCallback(function (notification) {
        var newNotification = __assign({ id: getNotificationId() }, notification);
        newNotification.onDismiss = function () {
            dismiss(newNotification.id);
        };
        setNotifications(function (notifications) {
            if (notifications.some(function (notification) { return notification.id === newNotification.id; }))
                return notifications;
            return notification.pushToTheEnd
                ? __spread(notifications, [newNotification]) : __spread([newNotification], notifications);
        });
        if (notification.timeout)
            setTimeout(function () {
                isMounted.current && dismiss(newNotification.id);
            }, notification.timeout);
    }, [dismiss, isMounted]);
    var clear = useCallback(function (target) {
        if (!target)
            setNotifications([]);
        else
            setNotifications(function (notifications) {
                return notifications.filter(function (notification) { return notification.target !== target; });
            });
    }, []);
    return (React.createElement(NotificationContext.Provider, { value: { notifications: notifications, push: push, dismiss: dismiss, clear: clear } }, children));
});
export var NotificationArea = memo(function (_a) {
    var name = _a.name, clearOnUnMount = _a.clearOnUnMount, className = _a.className;
    var _b = useContext(NotificationContext), notifications = _b.notifications, clear = _b.clear;
    useEffect(function () {
        return function () { return clearOnUnMount && clear(name); };
    }, [clear, name, clearOnUnMount]);
    var filteredNotifications = useMemo(function () {
        return notifications.filter(function (notification) {
            return (!name && !notification.target) ||
                (name && notification.target === name);
        });
    }, [notifications, name]);
    return (React.createElement(NotificationListContainer, { hasChildren: filteredNotifications.length > 0, className: className }, filteredNotifications.map(function (notification) { return (React.createElement(NotificationMessage, __assign({ key: notification.id }, notification))); })));
});
var templateObject_1;
