import { useEffect } from "react";
export function useOutsideClick(references, onClickOutside) {
    useEffect(function () {
        var listener = function (e) {
            var isOutside = false;
            if (Array.isArray(references)) {
                isOutside = !references.some(function (reference) {
                    return reference.current && reference.current.contains(e.target);
                });
            }
            else {
                var reference = references;
                isOutside = !(reference.current && reference.current.contains(e.target));
            }
            if (isOutside) {
                onClickOutside(e);
            }
        };
        document.addEventListener("click", listener);
        return function () {
            document.removeEventListener("click", listener);
        };
    }, [onClickOutside, references]);
}
