import { __makeTemplateObject } from "tslib";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { Colors } from "../../../constants";
export var FormElement = forwardRef(function (_a, ref) {
    var children = _a.children, className = _a.className, label = _a.label, error = _a.error, errorMessageMarginTop = _a.errorMessageMarginTop, id = _a.id;
    return (React.createElement(Container, { className: className, tabIndex: -1, ref: ref },
        label && React.createElement(Label, { htmlFor: id }, label),
        children,
        React.createElement(InvalidFeedback, { marginTop: errorMessageMarginTop }, error && typeof error === "string" ? error : "")));
});
var InvalidFeedback = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 16px;\n  color: ", ";\n  min-height: 48px;\n  padding-top: 4px;\n  margin-top: ", "px;\n"], ["\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 16px;\n  color: ", ";\n  min-height: 48px;\n  padding-top: 4px;\n  margin-top: ", "px;\n"])), Colors.Red, function (props) { return props.marginTop || 0; });
var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n  color: ", ";\n"], ["\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n  color: ", ";\n"])), Colors.Gray5);
var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  &:focus {\n    border: inherit;\n    outline: inherit;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  &:focus {\n    border: inherit;\n    outline: inherit;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
