import { __makeTemplateObject } from "tslib";
import React from "react";
import { Button, ButtonKind } from "../../Button";
import styled from "styled-components";
export var DefaultFooter = function (_a) {
    var onClickClose = _a.onClickClose, onClickSubmit = _a.onClickSubmit, _b = _a.submitText, submitText = _b === void 0 ? "Submit" : _b, isSubmitButtonBusy = _a.isSubmitButtonBusy, form = _a.form;
    return (React.createElement(Container, null,
        React.createElement(Button, { onClick: onClickClose, kind: ButtonKind.SECONDARY }, "Cancel"),
        React.createElement(Button, { onClick: onClickSubmit, form: form, type: form ? "submit" : undefined, busy: isSubmitButtonBusy }, submitText)));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  button:first-child {\n    margin-right: auto;\n  }\n  button:last-child {\n    margin-left: auto;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  button:first-child {\n    margin-right: auto;\n  }\n  button:last-child {\n    margin-left: auto;\n  }\n"])));
var templateObject_1;
