import { __assign, __awaiter, __generator, __read } from "tslib";
import React, { createContext, useCallback, useContext, useState, } from "react";
import { Modal } from "../Modal";
import { Colors } from "../../../constants";
import { ButtonKind } from "../../Button";
import { Portal } from "../../Portal";
var ConfirmationDialogContext = createContext({
    show: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, false];
    }); }); },
    isOpen: false,
});
export var ConfirmationDialogProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState({
        isOpen: false,
        onClickAffirmative: function () { return undefined; },
        onClickNegative: function () { return undefined; },
        content: "",
        affirmativeButtonText: "Yes",
        negativeButtonText: "No",
        title: "",
        affirmativeButtonColor: Colors.Red,
        negativeButtonColor: Colors.PrimaryBlue,
    }), 2), _c = _b[0], isOpen = _c.isOpen, onClickAffirmative = _c.onClickAffirmative, onClickNegative = _c.onClickNegative, content = _c.content, negativeButtonText = _c.negativeButtonText, affirmativeButtonText = _c.affirmativeButtonText, title = _c.title, affirmativeButtonColor = _c.affirmativeButtonColor, negativeButtonColor = _c.negativeButtonColor, setState = _b[1];
    var show = useCallback(function (_a) {
        var content = _a.content, affirmativeButtonText = _a.affirmativeButtonText, negativeButtonText = _a.negativeButtonText, _b = _a.title, title = _b === void 0 ? "" : _b, _c = _a.affirmativeButtonColor, affirmativeButtonColor = _c === void 0 ? Colors.Red : _c, _d = _a.negativeButtonColor, negativeButtonColor = _d === void 0 ? Colors.PrimaryBlue : _d;
        return new Promise(function (resolve) {
            var onClickNegative = function () {
                setState(function (state) { return (__assign(__assign({}, state), { isOpen: false })); });
                resolve(false);
            };
            var onClickAffirmative = function () {
                setState(function (state) { return (__assign(__assign({}, state), { isOpen: false })); });
                resolve(true);
            };
            setState(function (state) { return (__assign(__assign({}, state), { isOpen: true, content: content,
                onClickNegative: onClickNegative,
                onClickAffirmative: onClickAffirmative, affirmativeButtonText: affirmativeButtonText || state.affirmativeButtonText, negativeButtonText: negativeButtonText || state.negativeButtonText, title: title,
                affirmativeButtonColor: affirmativeButtonColor,
                negativeButtonColor: negativeButtonColor })); });
        });
    }, []);
    return (React.createElement(ConfirmationDialogContext.Provider, { value: { show: show, isOpen: isOpen } },
        children,
        isOpen && (React.createElement(Portal, null,
            React.createElement(Modal, { title: title, onClickClose: onClickNegative, isSmall: true, leftButtonText: negativeButtonText, leftButtonColor: negativeButtonColor, leftButtonKind: ButtonKind.GHOST, onClickLeftButton: onClickNegative, rightButtonText: affirmativeButtonText, rightButtonColor: affirmativeButtonColor, onClickRightButton: onClickAffirmative }, content)))));
};
export var useConfirmationDialog = function () {
    var _a = useContext(ConfirmationDialogContext), isOpen = _a.isOpen, show = _a.show;
    return [show, isOpen];
};
