import React, { useEffect } from "react";
import { Route, RouteProps, Redirect } from "react-router";
import { useAuth, AppState } from "./AuthProvider";

export type PrivateRouteProps = RouteProps & {
  /**
   *
   *
   * WILL BE DEPRECATED SOON.
   * Makes sure the user has all of the roles passed before rendering the route
   */
  allowedRoles?: string[];
  /**
   *
   *
   * Sets the content to render while initial authentication is still in progress
   */
  loadingRenderer?: React.ReactElement | string;
  /**
   *
   *
   * Sets the path to redirect to when authorization fails. Defaults to "/"
   */
  redirectIfNotAuthorized?: string;
  /**
   *
   *
   * Makes sure the user has all of the permissions passed before rendering the route
   */
  requiredPermissions?: string[];
  /**
   *
   *
   * Makes sure the user has at least one of the permissions passed before rendering the route.
   * if this prop is specified and the user has any of the permissions passed,
   * even if the authorization fails due to requiredPermissions check, the user will be granted access to the route.
   */
  fallbackPermissions?: string[];
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  allowedRoles,
  requiredPermissions,
  fallbackPermissions,
  loadingRenderer = "Loading...",
  redirectIfNotAuthorized = "/",
  ...rest
}) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    isAuthorized,
  } = useAuth();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      const appState: AppState = {
        pathname: window.location.pathname,
        expiresOn: Date.now() + 3 * 60 * 1000, // now + 3 minutes in milliseconds
      };

      await loginWithRedirect({
        appState,
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  if (loading || !isAuthenticated) return <>{loadingRenderer}</>;

  let userIsAuthorized = isAuthorized(
    allowedRoles,
    requiredPermissions,
    fallbackPermissions
  );

  if (userIsAuthorized) return <Route path={path} {...rest} />;
  else
    return (
      <Route
        path={path}
        exact={rest.exact}
        sensitive={rest.sensitive}
        strict={rest.strict}
        location={rest.location}
      >
        <Redirect to={redirectIfNotAuthorized} />
      </Route>
    );
};
