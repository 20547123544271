import { __assign, __makeTemplateObject, __rest } from "tslib";
import React, { useMemo } from "react";
import { Body2 } from "../Text";
import { Tooltip } from "./BaseTooltip";
import styled from "styled-components";
import { shade } from "polished";
import { Colors } from "../../constants/colors";
export var DefinitionTooltip = function (_a) {
    var children = _a.children, text = _a.text, _b = _a.isSmall, isSmall = _b === void 0 ? true : _b, _c = _a.textComponent, TextComponent = _c === void 0 ? Body2 : _c, _d = _a.color, color = _d === void 0 ? Colors.PrimaryBlue : _d, props = __rest(_a, ["children", "text", "isSmall", "textComponent", "color"]);
    var referenceElement = useMemo(function () {
        return (React.createElement(TextContainer, { color: color },
            React.createElement(TextComponent, null, text)));
    }, [text, color]);
    return (React.createElement(Tooltip, __assign({ referenceElement: referenceElement, placement: "bottom", trigger: "onHover" }, props), children));
};
var TextContainer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-decoration-style: dashed;\n  text-decoration-line: underline;\n  color: ", ";\n"], ["\n  text-decoration-style: dashed;\n  text-decoration-line: underline;\n  color: ", ";\n"])), function (props) { return shade(0.25, props.color); });
var templateObject_1;
