import { __assign, __makeTemplateObject } from "tslib";
import React, { useRef, forwardRef, useMemo, } from "react";
import { usePopper } from "react-popper";
import { Portal } from "../Portal";
import styled, { css } from "styled-components";
import { Colors } from "../../constants";
export var Popover = forwardRef(function (_a, ref) {
    var children = _a.children, reference = _a.reference, isOpen = _a.isOpen, onMouseLeave = _a.onMouseLeave, placement = _a.placement, _b = _a.className, className = _b === void 0 ? "" : _b, _c = _a.offset, offset = _c === void 0 ? [0, 0] : _c, hasArrow = _a.hasArrow, notInPortal = _a.notInPortal, isLight = _a.isLight, id = _a.id, onClickContent = _a.onClickContent;
    var container = useRef(null);
    var popperElement = useRef(null);
    var arrowElement = useRef(null);
    var _d = usePopper(reference.current, popperElement.current, {
        modifiers: [
            { name: "arrow", options: { element: arrowElement.current } },
            { name: "offset", options: { offset: offset } },
        ],
        placement: placement,
    }), styles = _d.styles, attributes = _d.attributes, state = _d.state;
    var arrowPlacement = useMemo(function () {
        return state === null || state === void 0 ? void 0 : state.placement.split("-")[0];
    }, [state]);
    var content = (React.createElement(React.Fragment, null,
        React.createElement(PopoverContainer, __assign({ onMouseLeave: onMouseLeave, className: className, role: "tooltip", isOpen: isOpen, ref: popperElement, style: styles.popper }, attributes.popper, { arrowPlacement: hasArrow ? arrowPlacement : undefined, isLight: isLight }),
            hasArrow && (React.createElement(Arrow, { ref: arrowElement, style: styles.arrow, placement: arrowPlacement })),
            React.createElement("div", { ref: ref, className: "popover-content", onClick: onClickContent }, children))));
    return (React.createElement("div", { ref: container, id: id }, notInPortal ? content : React.createElement(Portal, null, content)));
});
var arrowTopCSS = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  bottom: 0;\n\n  &::before {\n    top: 0;\n    border-width: 0.5rem 0.5rem 0;\n    border-top-color: #121212 !important;\n  }\n"], ["\n  bottom: 0;\n\n  &::before {\n    top: 0;\n    border-width: 0.5rem 0.5rem 0;\n    border-top-color: #121212 !important;\n  }\n"])));
var arrowRightCSS = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  left: 0;\n  width: 0.5rem;\n  height: 1rem;\n  z-index: 10;\n\n  &::before {\n    right: 0;\n    z-index: 10;\n\n    bottom: 3px;\n    border-width: 0.5rem 0.5rem 0.5rem 0;\n    border-right-color: #121212 !important;\n  }\n"], ["\n  left: 0;\n  width: 0.5rem;\n  height: 1rem;\n  z-index: 10;\n\n  &::before {\n    right: 0;\n    z-index: 10;\n\n    bottom: 3px;\n    border-width: 0.5rem 0.5rem 0.5rem 0;\n    border-right-color: #121212 !important;\n  }\n"])));
var arrowBottomCSS = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  top: 0px;\n\n  &::before {\n    bottom: 0;\n    border-width: 0 0.5rem 0.5rem;\n    border-bottom-color: #121212 !important;\n  }\n"], ["\n  top: 0px;\n\n  &::before {\n    bottom: 0;\n    border-width: 0 0.5rem 0.5rem;\n    border-bottom-color: #121212 !important;\n  }\n"])));
var arrowLeftCSS = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  right: 0;\n  width: 0.5rem;\n  height: 1rem;\n\n  &::before {\n    left: 0;\n    bottom: 3px;\n\n    border-width: 0.5rem 0 0.5rem 0.5rem;\n    border-left-color: #121212 !important;\n  }\n"], ["\n  right: 0;\n  width: 0.5rem;\n  height: 1rem;\n\n  &::before {\n    left: 0;\n    bottom: 3px;\n\n    border-width: 0.5rem 0 0.5rem 0.5rem;\n    border-left-color: #121212 !important;\n  }\n"])));
var PopoverContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  visibility: ", ";\n  position: absolute;\n  display: flex;\n  word-wrap: break-word;\n  z-index: 99998;\n\n  ", "\n\n  & > .popover-content {\n    z-index: 99999;\n\n    ", "\n    background: ", ";\n    color: ", ";\n    box-shadow: 0px 4px 16px rgba(12, 12, 13, 0.1);\n    border-radius: 6px;\n    padding: 16px 8px;\n  }\n"], ["\n  visibility: ", ";\n  position: absolute;\n  display: flex;\n  word-wrap: break-word;\n  z-index: 99998;\n\n  ",
    "\n\n  & > .popover-content {\n    z-index: 99999;\n\n    ",
    "\n    background: ", ";\n    color: ", ";\n    box-shadow: 0px 4px 16px rgba(12, 12, 13, 0.1);\n    border-radius: 6px;\n    padding: 16px 8px;\n  }\n"])), function (props) { return (props.isOpen ? "unset" : "hidden"); }, function (_a) {
    var arrowPlacement = _a.arrowPlacement;
    switch (arrowPlacement) {
        case "bottom":
            return "padding-top: 7px;";
        case "top":
            return "padding-bottom: 7px;";
        case "left":
            return "padding-right: 7px;";
        case "right":
            return "padding-left: 7px;";
    }
}, function (_a) {
    var arrowPlacement = _a.arrowPlacement;
    switch (arrowPlacement) {
        case "bottom":
            return "padding-top: 8px;";
        case "top":
            return "padding-bottom: 8px;";
        case "left":
            return "margin-top:auto; margin-right:auto;";
        case "right":
            return "margin-top:auto; margin-right:auto;";
    }
}, function (_a) {
    var isLight = _a.isLight;
    return (isLight ? "white" : Colors.PrimaryBlack);
}, function (_a) {
    var isLight = _a.isLight;
    return (isLight ? Colors.PrimaryBlack : "white");
});
var Arrow = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  display: block;\n  width: 1rem;\n  height: 0.5rem;\n\n  &::before {\n    position: absolute;\n    content: \"\";\n    border-color: transparent;\n    border-style: solid;\n  }\n\n  ", "\n"], ["\n  position: absolute;\n  display: block;\n  width: 1rem;\n  height: 0.5rem;\n\n  &::before {\n    position: absolute;\n    content: \"\";\n    border-color: transparent;\n    border-style: solid;\n  }\n\n  ",
    "\n"])), function (_a) {
    var placement = _a.placement;
    switch (placement) {
        case "bottom":
            return arrowBottomCSS;
        case "top":
            return arrowTopCSS;
        case "left":
            return arrowLeftCSS;
        case "right":
            return arrowRightCSS;
    }
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
