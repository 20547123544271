import { __makeTemplateObject } from "tslib";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as FireflyLogo } from "./firefly.svg";
import { ReactComponent as AdopsLogo } from "./adops.svg";
import { ReactComponent as SupplyopsLogo } from "./supplyops.svg";
import styled from "styled-components";
var _Logo = function (_a) {
    var className = _a.className, realm = _a.realm;
    return (React.createElement(Link, { to: "/", "data-testid": "firefly-logo", className: className },
        React.createElement(FireflyLogo, null),
        realm === "adops" && React.createElement(AdopsLogo, null),
        realm === "supplyops" && React.createElement(SupplyopsLogo, null)));
};
export var Logo = styled(_Logo)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  svg:first-child {\n    margin-right: 18px;\n  }\n"], ["\n  display: flex;\n  svg:first-child {\n    margin-right: 18px;\n  }\n"])));
var templateObject_1;
