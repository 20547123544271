export var getObjectValue = function (object, field, seperator) {
    if (seperator === void 0) { seperator = "."; }
    if (typeof field !== "string" && Array.isArray(field))
        return field.map(function (_field) { return getObjectValue(object, _field, seperator); });
    var levels = field.split(seperator);
    var value = object[levels[0]];
    if (levels.length === 0)
        return undefined;
    if (levels.length === 1)
        return value;
    if (Array.isArray(value))
        return value
            .map(function (val) { return getObjectValue(val, levels.slice(1).join(seperator)); })
            .join("");
    else
        return getObjectValue(value, levels.slice(1).join(seperator));
};
