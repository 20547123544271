import { __assign, __makeTemplateObject, __read, __rest } from "tslib";
import React, { useRef, useState, useCallback, useMemo, useEffect, } from "react";
import styled from "styled-components";
import { Popover } from "../Popover";
import { useOutsideClick } from "../../hooks";
import { H5 } from "../Text";
export var Tooltip = function (_a) {
    var children = _a.children, _referenceElement = _a.referenceElement, reference = _a.reference, _b = _a.trigger, trigger = _b === void 0 ? "onHover" : _b, _c = _a.placement, placement = _c === void 0 ? "right" : _c, _d = _a.titleComponent, TitleComponent = _d === void 0 ? H5 : _d, title = _a.title, rest = __rest(_a, ["children", "referenceElement", "reference", "trigger", "placement", "titleComponent", "title"]);
    var triggerRef = useRef(null);
    var dropdownRef = useRef(null);
    var _e = __read(useState(false), 2), isOpen = _e[0], setIsOpen = _e[1];
    var toggle = useCallback(function () {
        setIsOpen(function (state) { return !state; });
    }, []);
    var close = useCallback(function () {
        setIsOpen(false);
    }, []);
    var open = useCallback(function () { return setIsOpen(true); }, []);
    var referenceSet = useMemo(function () {
        var set = [dropdownRef];
        if (_referenceElement)
            set.push(triggerRef);
        if (reference)
            set.push(reference);
        return set;
    }, [reference, _referenceElement]);
    useOutsideClick(referenceSet, close);
    var onClickContent = useCallback(function (e) {
        if (e.target instanceof HTMLButtonElement)
            close();
    }, [close]);
    var referenceElement = useMemo(function () {
        if (_referenceElement) {
            var props = {
                ref: triggerRef,
            };
            if (trigger === "onClick")
                props.onClick = toggle;
            if (trigger === "onHover") {
                props.onMouseEnter = toggle;
                props.onMouseLeave = toggle;
            }
            return React.cloneElement(_referenceElement, props);
        }
    }, [_referenceElement, toggle, trigger]);
    useEffect(function () {
        if (!reference)
            return;
        if (trigger === "onClick") {
            var refElement_1 = reference.current;
            refElement_1 === null || refElement_1 === void 0 ? void 0 : refElement_1.addEventListener("click", toggle);
            return function () { return refElement_1 === null || refElement_1 === void 0 ? void 0 : refElement_1.removeEventListener("click", toggle); };
        }
        else if (trigger === "onHover") {
            var refElement_2 = reference.current;
            refElement_2 === null || refElement_2 === void 0 ? void 0 : refElement_2.addEventListener("mouseenter", open);
            refElement_2 === null || refElement_2 === void 0 ? void 0 : refElement_2.addEventListener("mouseleave", close);
            return function () {
                refElement_2 === null || refElement_2 === void 0 ? void 0 : refElement_2.removeEventListener("mouseenter", open);
                refElement_2 === null || refElement_2 === void 0 ? void 0 : refElement_2.removeEventListener("mouseleave", close);
            };
        }
    }, [reference, trigger, toggle, close, open]);
    return (React.createElement(React.Fragment, null,
        referenceElement,
        React.createElement(StyledPopover, __assign({}, rest, { isOpen: isOpen, reference: reference || triggerRef, ref: dropdownRef, onClickContent: onClickContent, hasArrow: true, placement: placement }),
            title && (React.createElement(TitleContainer, null,
                React.createElement(TitleComponent, null, title))),
            children)));
};
var StyledPopover = styled(Popover)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > .popover-content {\n    padding: ", ";\n    ", "\n\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 20px;\n    letter-spacing: 0.1px;\n  }\n"], ["\n  & > .popover-content {\n    padding: ", ";\n    ", "\n\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 20px;\n    letter-spacing: 0.1px;\n  }\n"])), function (_a) {
    var isSmall = _a.isSmall;
    return (isSmall ? "8px 16px" : "32px 24px;");
}, function (_a) {
    var isSmall = _a.isSmall;
    return (isSmall ? "max-width: 298px;" : "width:240px;");
});
var TitleContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 8px;\n"], ["\n  margin-bottom: 8px;\n"])));
var templateObject_1, templateObject_2;
