export var Colors;
(function (Colors) {
    Colors["PrimaryBlue"] = "#3675FF";
    Colors["PrimaryBlack"] = "#121212";
    Colors["Gray1"] = "#FAFBFC";
    Colors["Gray2"] = "#F4F5F7";
    Colors["Gray3"] = "#DFE1E6";
    Colors["Gray4"] = "#BFC4CE";
    Colors["Gray5"] = "#5E6C84";
    Colors["Red"] = "#FF453A";
    Colors["Orange"] = "#FF802E";
    Colors["Yellow"] = "#FFBA00";
    Colors["Green"] = "#00D553";
    Colors["Cyan"] = "#22BFFF";
    Colors["Blue"] = "#0A84FF";
    Colors["Indigo"] = "#5E5CE6";
    Colors["Purple"] = "#BF5AF2";
    Colors["Pink"] = "#FF4B7B";
})(Colors || (Colors = {}));
