import { __makeTemplateObject, __read } from "tslib";
import React from "react";
import styled from "styled-components";
import { spinnerAnimation } from "../../animations";
import { useLottie, Lottie } from "react-lottie-hook";
var _InlineSpinner = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b, _c = _a.width, width = _c === void 0 ? 16 : _c, _d = _a.height, height = _d === void 0 ? 16 : _d;
    var _e = __read(useLottie({
        renderer: "svg",
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet",
            progressiveLoad: true,
        },
        animationData: spinnerAnimation,
    }), 1), lottieRef = _e[0];
    return (React.createElement(Lottie, { lottieRef: lottieRef, className: className + " inline-spinner", width: width, height: height }));
};
export var InlineSpinner = styled(_InlineSpinner)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n"], ["\n  margin: 0;\n"])));
var templateObject_1;
