import { __makeTemplateObject } from "tslib";
import React from "react";
import { H2, Body1 } from "../Text";
import styled from "styled-components";
import { Colors } from "../../constants/colors";
import { TabLinkContainer } from "../Tabs";
export var PageHeader = function (_a) {
    var className = _a.className, title = _a.title, description = _a.description, actions = _a.actions, tabs = _a.tabs, expand = _a.expand;
    return (React.createElement(MainContainer, { className: className, expand: expand },
        React.createElement(Container, null,
            React.createElement("span", null,
                title && typeof title === "string" && (React.createElement(H2, { "data-testid": "page-title" }, title)),
                title && typeof title !== "string" && title,
                description && (React.createElement(Description, { "data-testid": "page-description" }, description))),
            actions && React.createElement(ActionsContainer, null, actions)),
        tabs && React.createElement(TabLinkContainer, { tabs: tabs, headerTabs: true })));
};
var MainContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 24px 30px 0 30px;\n  background-color: white;\n  box-shadow: 0px 4px 16px rgba(12, 12, 13, 0.1);\n  margin: 0 ", ";\n  flex-shrink: 0;\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 24px 30px 0 30px;\n  background-color: white;\n  box-shadow: 0px 4px 16px rgba(12, 12, 13, 0.1);\n  margin: 0 ", ";\n  flex-shrink: 0;\n"])), function (props) { return (props.expand ? "-30px" : "0"); });
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  padding-bottom: 30px;\n"], ["\n  display: flex;\n  padding-bottom: 30px;\n"])));
var Description = styled(Body1).attrs({ light: true })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  margin-left: 16px;\n"], ["\n  color: ", ";\n  margin-left: 16px;\n"])), Colors.Gray5);
var ActionsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  margin-left: auto;\n  margin-top: auto;\n  margin-bottom: auto;\n"], ["\n  display: flex;\n  margin-left: auto;\n  margin-top: auto;\n  margin-bottom: auto;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
