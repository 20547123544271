import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
import { useState, useEffect, useCallback } from "react";
import { useMountedCheck } from "./useMountedCheck";
function useFetch(fetcher) {
    var _this = this;
    var _a = __read(useState({
        isLoading: true,
    }), 2), _b = _a[0], data = _b.data, isLoading = _b.isLoading, setState = _a[1];
    var isMounted = useMountedCheck();
    var fetch = useCallback(function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isMounted.current && setState(function (state) { return (__assign(__assign({}, state), { isLoading: true })); });
                        return [4 /*yield*/, fetcher.apply(void 0, __spread(args))];
                    case 1:
                        response = _a.sent();
                        isMounted.current &&
                            setState(function (state) { return (__assign(__assign({}, state), { isLoading: false, data: response })); });
                        return [2 /*return*/];
                }
            });
        });
    }, [fetcher, isMounted]);
    var initialFetch = useCallback(function () {
        if (data)
            return;
        //@ts-expect-error
        fetch();
    }, [fetch, data]);
    useEffect(function () {
        initialFetch();
    }, [initialFetch]);
    return [data, isLoading, fetch];
}
export { useFetch };
