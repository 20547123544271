import { __assign, __rest } from "tslib";
import { Colors } from "../../../constants";
import { tint } from "polished";
export var customStyle = {
    control: function (styles, _a) {
        var isDisabled = _a.isDisabled;
        return (__assign(__assign({}, styles), { backgroundColor: Colors.Gray1, borderColor: isDisabled ? Colors.Gray1 : Colors.Gray3, boxShadow: "none", ":hover": {
                backgroundColor: "#F4F5F7",
            } }));
    },
    input: function (styles) { return (__assign(__assign({}, styles), { color: Colors.Gray5, fontSize: "14px", fontWeight: "normal" })); },
    valueContainer: function (styles) { return (__assign(__assign({}, styles), { paddingLeft: "1rem", fontSize: "14px", fontWeight: "normal" })); },
    singleValue: function (styles, _a) {
        var isDisabled = _a.isDisabled;
        return __assign(__assign({}, styles), { color: isDisabled ? tint(0.5, Colors.PrimaryBlack) : Colors.PrimaryBlack });
    },
    indicatorsContainer: function (styles, _a) {
        var isDisabled = _a.isDisabled;
        return __assign(__assign({}, styles), { marginTop: "auto", marginBottom: "auto", "&>svg": {
                path: {
                    fill: isDisabled
                        ? tint(0.5, Colors.PrimaryBlack)
                        : Colors.PrimaryBlack,
                },
            } });
    },
    option: function (styles, _a) {
        var data = _a.data, isDisabled = _a.isDisabled, isFocused = _a.isFocused, isSelected = _a.isSelected, rest = __rest(_a, ["data", "isDisabled", "isFocused", "isSelected"]);
        return __assign(__assign({}, styles), { backgroundColor: "white", color: isDisabled ? "#ccc" : Colors.PrimaryBlack, cursor: isDisabled ? "not-allowed" : "default", fontWeight: "normal", fontSize: "14px", lineHeight: "20px", ":hover": {
                backgroundColor: Colors.Gray2,
            }, ":active": __assign(__assign({}, styles[":active"]), { backgroundColor: !isDisabled && Colors.PrimaryBlue + " !important", color: "white", borderRadius: "4px" }) });
    },
    menu: function (styles) { return (__assign(__assign({}, styles), { padding: "16px 8px", boxShadow: "0px 4px 8px rgba(18, 18, 18, 0.08)", border: "1px solid " + Colors.Gray2 })); },
    menuList: function (styles) { return (__assign(__assign({}, styles), { padding: 0 })); },
    multiValue: function (styles, _a) {
        var data = _a.data;
        return __assign(__assign({}, styles), { backgroundColor: "#DFE1E6", color: "#121212", borderRadius: "14px", padding: "3px 8px", fontSize: "14px", lineHeight: "18px", fontWeight: "600", display: "inline-flex" });
    },
    multiValueLabel: function (styles, _a) {
        var data = _a.data;
        return (__assign(__assign({}, styles), { color: data.color, padding: 0 }));
    },
    menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 9999999999 })); },
    multiValueRemove: function (styles, _a) {
        var data = _a.data;
        return (__assign(__assign({}, styles), { color: "black", padding: "2px", borderRadius: "100%", marginTop: "auto", marginBottom: "auto", marginLeft: "4px", ":hover": {
                backgroundColor: "#BFC4CE",
                color: "white",
            }, svg: {
                path: { fill: "black" },
            } }));
    },
};
