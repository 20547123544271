import { __assign, __makeTemplateObject, __rest } from "tslib";
import React, { useRef, useEffect } from "react";
import { Navbar } from "../../components/Navbar";
import { Footer } from "../../components/Footer";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
export var Layout = function (_a) {
    var children = _a.children, noFooter = _a.noFooter, rest = __rest(_a, ["children", "noFooter"]);
    var contentContainerRef = useRef(null);
    useEffect(function () {
        var _a;
        var rootContainer = (_a = contentContainerRef.current) === null || _a === void 0 ? void 0 : _a.parentElement;
        rootContainer === null || rootContainer === void 0 ? void 0 : rootContainer.classList.add("firestrap-container");
        return function () { return rootContainer === null || rootContainer === void 0 ? void 0 : rootContainer.classList.remove("firestrap-container"); };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyle, null),
        React.createElement(Navbar, __assign({}, rest)),
        React.createElement(ContentContainer, { ref: contentContainerRef }, children),
        !noFooter && React.createElement(Footer, null)));
};
export var GlobalStyle = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  html {\n    height: 100%;\n    width:100%;\n    display: flex;\n  }\n\n  body {\n    font-family: Nunito Sans,-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\";;\n    letter-spacing: 0.1px;\n    display: flex;\n    flex-grow: 1;\n    background-color: #f9f9fb;\n    margin: 0;\n    width:100%;\n  }\n\n  .firestrap-container{\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    width:100%;\n  }\n\n"], ["\n  html {\n    height: 100%;\n    width:100%;\n    display: flex;\n  }\n\n  body {\n    font-family: Nunito Sans,-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\";;\n    letter-spacing: 0.1px;\n    display: flex;\n    flex-grow: 1;\n    background-color: #f9f9fb;\n    margin: 0;\n    width:100%;\n  }\n\n  .firestrap-container{\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    width:100%;\n  }\n\n"])));
var ContentContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 30px;\n  padding-right: 30px;\n  margin-top: 56px;\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  width: 100%;\n  margin-right: auto;\n  margin-left: auto;\n"], ["\n  padding-left: 30px;\n  padding-right: 30px;\n  margin-top: 56px;\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  width: 100%;\n  margin-right: auto;\n  margin-left: auto;\n"])));
var templateObject_1, templateObject_2;
