import { __assign, __rest } from "tslib";
import React, { useRef, useCallback, useMemo } from "react";
import ReactDatePicker from "react-datepicker";
import "./style.scss";
import { getElementId } from "../../../utils/elementIdGenerator";
import { FormElement } from "../FormElement";
import { Input } from "../Input";
var defaultOnChange = function () { return undefined; };
var defaultDateFormat = "MMM dd, yyyy";
var defaultDateTimeFormat = "MMM dd, yyyy | hh:mm a";
export var DatePicker = function (_a) {
    var value = _a.value, label = _a.label, _b = _a.onChange, onChange = _b === void 0 ? defaultOnChange : _b, id = _a.id, error = _a.error, _c = _a.isFormElement, isFormElement = _c === void 0 ? true : _c, rest = __rest(_a, ["value", "label", "onChange", "id", "error", "isFormElement"]);
    var idRef = useRef(id || getElementId("datepicker"));
    var formatWeekDay = useCallback(function (date) { return date[0]; }, []);
    var dateFormat = useMemo(function () {
        if (rest.dateFormat)
            return rest.dateFormat;
        else
            return rest.showTimeSelect ? defaultDateTimeFormat : defaultDateFormat;
    }, [rest.dateFormat, rest.showTimeSelect]);
    var component = (React.createElement(ReactDatePicker, __assign({ tabIndex: -1, selected: value, onChange: onChange, id: idRef.current, className: "datepicker-input " + (error ? "is-invalid" : ""), wrapperClassName: "firestrap-datepicker", calendarClassName: "firestrap-calendar", showPopperArrow: false, formatWeekDay: formatWeekDay, dateFormat: dateFormat, timeFormat: "hh:mm a", timeIntervals: 15, autoComplete: "off", timeCaption: "TIME", customInput: React.createElement(Input, { isFormElement: false }) }, rest)));
    return isFormElement ? (React.createElement(FormElement, { error: error, label: label }, component)) : (component);
};
