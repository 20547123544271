import { __makeTemplateObject } from "tslib";
import React from "react";
import { ReactComponent as NotificationIcon } from "./notification.svg";
import AnnounceKit from "announcekit-react";
import styled from "styled-components";
var _Notification = function (_a) {
    var className = _a.className;
    return (React.createElement("div", { className: className },
        React.createElement(NotificationIcon, { className: "ak-trigger", "data-testid": "header-notifications" }),
        React.createElement(AnnounceKit, { catchClick: ".ak-trigger", widget: "https://feed.fireflyon.com/widgets/v2/3dRNXG" })));
};
export var Notification = styled(_Notification)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n\n  svg {\n    margin-top: auto;\n    margin-bottom: auto;\n  }\n  div {\n    top: -3px;\n    right: -3px;\n    opacity: 0.9;\n    border: 1px solid transparent !important;\n    position: absolute !important;\n    color: red !important;\n  }\n"], ["\n  position: relative;\n  display: flex;\n\n  svg {\n    margin-top: auto;\n    margin-bottom: auto;\n  }\n  div {\n    top: -3px;\n    right: -3px;\n    opacity: 0.9;\n    border: 1px solid transparent !important;\n    position: absolute !important;\n    color: red !important;\n  }\n"])));
var templateObject_1;
