import { __makeTemplateObject } from "tslib";
import React, { useCallback } from "react";
import styled from "styled-components";
import { Colors } from "../../../constants/colors";
import { ButtonKind, Button } from "../../Button";
import { tint } from "polished";
import { TrashIcon, PDFIcon } from "../../../icons";
export var FilePreview = function (_a) {
    var file = _a.file, onClickRemoveFile = _a.onClickRemoveFile;
    var onClickRemoveFileButton = useCallback(function () {
        onClickRemoveFile(file.id);
    }, [onClickRemoveFile, file.id]);
    var Thumbnail = FileThumbnailImage;
    switch (file.type) {
        case "application/pdf":
            Thumbnail = FileThumbnailPDF;
            break;
    }
    return (React.createElement(FilePreviewContainer, { key: file.id },
        React.createElement(FileThumbnailContainer, null,
            React.createElement(Thumbnail, { file: file })),
        React.createElement(DeleteButton, { kind: ButtonKind.GHOST, icon: React.createElement(TrashIcon, null), color: tint(0.25, Colors.PrimaryBlack), onClick: onClickRemoveFileButton })));
};
var FileThumbnailImage = function (_a) {
    var file = _a.file;
    return React.createElement(ImageThumbnail, { src: file.content, alt: "" });
};
var FileThumbnailPDF = function () {
    return React.createElement(PDFIcon, null);
};
var FilePreviewContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px;\n  width: 100%;\n  display: flex;\n  background: white;\n  justify-content: space-between;\n  &:nth-child(odd) {\n    background-color: ", ";\n  }\n"], ["\n  padding: 16px;\n  width: 100%;\n  display: flex;\n  background: white;\n  justify-content: space-between;\n  &:nth-child(odd) {\n    background-color: ", ";\n  }\n"])), Colors.Gray1);
var FileThumbnailContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: white;\n  border: 1px solid ", ";\n  box-shadow: 0px 1px 4px rgba(12, 12, 13, 0.1);\n  border-radius: 4px;\n  width: 170px;\n  height: 240px;\n  display: flex;\n  svg {\n    margin: auto;\n  }\n"], ["\n  background: white;\n  border: 1px solid ", ";\n  box-shadow: 0px 1px 4px rgba(12, 12, 13, 0.1);\n  border-radius: 4px;\n  width: 170px;\n  height: 240px;\n  display: flex;\n  svg {\n    margin: auto;\n  }\n"])), Colors.Gray3);
var DeleteButton = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: auto;\n  margin-bottom: auto;\n"], ["\n  margin-top: auto;\n  margin-bottom: auto;\n"])));
var ImageThumbnail = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n"], ["\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
