import _isEqual from "react-fast-compare";
export var arraysEqualIgnoreOrder = function (array1, array2) {
    if (array1 === array2)
        return true;
    if ((array1 === null || array1 === void 0 ? void 0 : array1.length) !== (array2 === null || array2 === void 0 ? void 0 : array2.length))
        return false;
    var length = array1.length;
    array1.sort();
    array2.sort();
    for (var i = 0; i < length; i++) {
        if (!isEqual(array1[i], array2[i], true))
            return false;
    }
    return true;
};
export var isEqual = function (a, b, ignoreOrder) {
    if (ignoreOrder === void 0) { ignoreOrder = false; }
    if (!ignoreOrder)
        return _isEqual(a, b);
    if ((Array.isArray(a), Array.isArray(b))) {
        return arraysEqualIgnoreOrder(a, b);
    }
    else
        return _isEqual(a, b);
};
