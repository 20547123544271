import { __makeTemplateObject } from "tslib";
import React from "react";
import styled from "styled-components";
import { ReactComponent as LoveIcon } from "./love.svg";
export var Footer = function () {
    return (React.createElement(StyledFooter, { className: "ff-footer" },
        React.createElement("div", null,
            React.createElement("div", { className: "container" },
                React.createElement("p", null,
                    "Made with ",
                    React.createElement(LoveIcon, null),
                    React.createElement("strong", null, "Istanbul & Ankara"))))));
};
export var StyledFooter = styled.footer(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: auto;\n  padding-top: 32px;\n\n  div {\n    width: 100%;\n    height: 2.5rem;\n    display: flex;\n    align-items: center;\n    background: white;\n\n    div {\n      display: flex;\n      justify-content: center;\n      p {\n        display: inline-flex;\n        align-items: center;\n        margin: 0;\n        font-size: 0.75em;\n        line-height: initial;\n        svg {\n          margin: 0 0.5rem;\n        }\n        strong {\n          margin-left: 0.3rem;\n          margin-top: 0.1rem;\n          font-weight: 700;\n        }\n      }\n    }\n  }\n"], ["\n  margin-top: auto;\n  padding-top: 32px;\n\n  div {\n    width: 100%;\n    height: 2.5rem;\n    display: flex;\n    align-items: center;\n    background: white;\n\n    div {\n      display: flex;\n      justify-content: center;\n      p {\n        display: inline-flex;\n        align-items: center;\n        margin: 0;\n        font-size: 0.75em;\n        line-height: initial;\n        svg {\n          margin: 0 0.5rem;\n        }\n        strong {\n          margin-left: 0.3rem;\n          margin-top: 0.1rem;\n          font-weight: 700;\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
