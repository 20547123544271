import { __makeTemplateObject } from "tslib";
import React from "react";
import { ReactComponent as SettingsIcon } from "./settings-icon.svg";
import { Bouncer } from "@fireflyon/auth";
import styled from "styled-components";
var getEnvironment = function () {
    var hostname = window.location.hostname;
    if (hostname.startsWith("localhost") ||
        hostname.startsWith("tst-") ||
        hostname.startsWith("beta-"))
        return "tst";
    if (hostname.startsWith("acc-"))
        return "acc";
    if (hostname.startsWith("preprod-"))
        return "preprod";
    else
        return "live";
};
var getEnvironmentPrefix = function () {
    var environment = getEnvironment();
    return environment === "live" ? "" : environment + "-";
};
export var Settings = function () {
    var content = (React.createElement(StyledLink, { href: "https://" + getEnvironmentPrefix() + "adm.fireflyon.com/settings", "data-testid": "header-settings" },
        React.createElement(SettingsIcon, null)));
    if (process.env.STORYBOOK_ENVIRONMENT)
        return content;
    else
        return (React.createElement(Bouncer, { requiredPermissions: ["manage_display_forecasts"] }, content));
};
var StyledLink = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  svg {\n    margin: auto 0;\n  }\n"], ["\n  display: flex;\n  svg {\n    margin: auto 0;\n  }\n"])));
var templateObject_1;
