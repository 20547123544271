import { __assign, __makeTemplateObject, __rest } from "tslib";
import React from "react";
import { CloseIcon } from "../../../icons";
import { Portal } from "../../Portal";
import { OverlayContainer } from "../OverlayContainer";
import styled from "styled-components";
import { useScrollLock } from "../useScrollLock";
import { Colors } from "../../../constants/colors";
import { H3 } from "../../Text";
import { Button, ButtonKind } from "../../Button";
import { DefaultModalFooter, } from "./DefaultModalFooter";
export var Modal = function (_a) {
    var title = _a.title, children = _a.children, onClickClose = _a.onClickClose, _footer = _a.footer, _b = _a.className, className = _b === void 0 ? "" : _b, isSmall = _a.isSmall, rest = __rest(_a, ["title", "children", "onClickClose", "footer", "className", "isSmall"]);
    useScrollLock();
    var footer = _footer || React.createElement(DefaultModalFooter, __assign({}, rest));
    return (React.createElement(Portal, null,
        React.createElement(OverlayContainer, { className: "" + className, role: "dialog" },
            React.createElement(ModalContainer, { isSmall: isSmall },
                React.createElement(ModalHeader, { isSmall: isSmall },
                    title && typeof title !== "string" && React.createElement("div", null, title),
                    title && typeof title === "string" && React.createElement(H3, null, title),
                    React.createElement(Button, { onClick: onClickClose, "data-testid": "modal-close-button", kind: ButtonKind.GHOST, color: Colors.PrimaryBlack, icon: React.createElement(CloseIcon, null) })),
                React.createElement(ModalBody, { isSmall: isSmall, "data-testid": "modal-content" }, children),
                footer && React.createElement(ModalFooter, { isSmall: isSmall }, footer)))));
};
var ModalContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  margin: auto;\n  width: ", "px;\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n  max-height: 900px;\n"], ["\n  position: relative;\n  margin: auto;\n  width: ", "px;\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n  max-height: 900px;\n"])), function (props) { return (props.isSmall ? 440 : 640); });
var ModalHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n  display: flex;\n  justify-content: space-between;\n  border-bottom: ", ";\n"], ["\n  background-color: ", ";\n  padding: ", ";\n  display: flex;\n  justify-content: space-between;\n  border-bottom: ",
    ";\n"])), function (props) { return (props.isSmall ? "none" : Colors.Gray1); }, function (props) { return (props.isSmall ? "32px" : "32px 48px"); }, function (props) {
    return props.isSmall ? "none" : "1px solid " + Colors.Gray3;
});
var ModalBody = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: ", ";\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 20px;\n  overflow-y: auto;\n"], ["\n  padding: ", ";\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 20px;\n  overflow-y: auto;\n"])), function (props) { return (props.isSmall ? "32px" : "32px 48px"); });
var ModalFooter = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  padding: ", ";\n  border-top: ", ";\n  margin-top: auto;\n"], ["\n  display: flex;\n  padding: ", ";\n  border-top: ",
    ";\n  margin-top: auto;\n"])), function (props) { return (props.isSmall ? "32px" : "24px 48px"); }, function (props) {
    return props.isSmall ? "none" : "1px solid " + Colors.Gray3;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
