import { __assign, __awaiter, __generator, __makeTemplateObject, __rest } from "tslib";
import React, { useCallback, useMemo, useRef } from "react";
import ReactSelect, { components, } from "react-select";
import { customStyle } from "./custom-style";
import { generateTestId, getElementId } from "../../../utils";
import { FormElement } from "../FormElement";
import styled from "styled-components";
import { ReactComponent as DownChevronIcon } from "./down-chevron.svg";
import { Colors } from "../../../constants/colors";
var Option = function (props) {
    return (React.createElement(components.Option, __assign({}, props),
        React.createElement("span", { "data-testid": generateTestId("select-option", props.label) }, props.label)));
};
var Input = function (props) {
    return (React.createElement(components.Input, __assign({}, props, { "data-testid": generateTestId("select-input", props.selectProps.name) })));
};
var StyledDownChevronIcon = styled(DownChevronIcon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 16px;\n  margin-left: 8px;\n"], ["\n  margin-right: 16px;\n  margin-left: 8px;\n"])));
var DropdownIndicator = function (props) {
    return React.createElement(StyledDownChevronIcon, null);
};
var IndicatorSeperator = function (props) { return React.createElement("span", null); };
var StyledControl = styled(components.Control)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-color: ", " !important;\n"], ["\n  border-color: ",
    " !important;\n"])), function (props) {
    if (props.selectProps.isDisabled)
        return Colors.Gray1;
    return props.selectProps.isInvalid ? Colors.Red : Colors.Gray3;
});
var Control = function (props) {
    return React.createElement(StyledControl, __assign({}, props));
};
var CUSTOM_COMPONENTS = {
    Option: Option,
    Input: Input,
    DropdownIndicator: DropdownIndicator,
    IndicatorSeperator: IndicatorSeperator,
    Control: Control,
};
export var Select = function (_a) {
    var label = _a.label, _value = _a.value, _defaultValue = _a.defaultValue, options = _a.options, _onChange = _a.onChange, _styles = _a.styles, error = _a.error, _b = _a.className, className = _b === void 0 ? "" : _b, _c = _a.isFormElement, isFormElement = _c === void 0 ? true : _c, id = _a.id, rest = __rest(_a, ["label", "value", "defaultValue", "options", "onChange", "styles", "error", "className", "isFormElement", "id"]);
    var onChange = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (_onChange) {
                if (!rest.isMulti) {
                    _onChange(event.value);
                }
                else {
                    _onChange(event === null || event === void 0 ? void 0 : event.map(function (e) { return e.value; }));
                }
            }
            return [2 /*return*/];
        });
    }); }, [_onChange, rest.isMulti]);
    var idRef = useRef(id || getElementId("select-element"));
    var value = useMemo(function () {
        var _a, _b;
        if (_value === null)
            return null;
        if (rest.isMulti) {
            if (!_value)
                return undefined;
            if (_value.length === 0)
                return [];
            return (_a = options) === null || _a === void 0 ? void 0 : _a.filter(function (option) {
                return _value.includes(option.value);
            });
        }
        else {
            return (_b = options) === null || _b === void 0 ? void 0 : _b.find(function (option) { return option.value === _value; });
        }
    }, [_value, options, rest.isMulti]);
    var defaultValue = useMemo(function () { var _a; return (_a = options) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.value === _defaultValue; }); }, [_defaultValue, options]);
    var style = useMemo(function () { return (__assign(__assign({}, customStyle), _styles)); }, [_styles]);
    var component = (
    //@ts-ignore
    React.createElement(StyledSelect, __assign({ maxMenuHeight: 132 }, rest, { options: options, onChange: onChange, value: value, defaultValue: defaultValue, styles: style, menuPortalTarget: document.body, className: (error ? "is-invalid" : "") + " " + (isFormElement ? "" : className), classNamePrefix: "select", instanceId: generateTestId("", rest.name), components: CUSTOM_COMPONENTS, id: idRef.current, isInvalid: Boolean(error) })));
    if (!isFormElement)
        return component;
    else
        return (React.createElement(FormElement, { label: label, error: error, id: idRef.current, className: className }, component));
};
var StyledSelect = styled(ReactSelect)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  .select__indicator-separator {\n    width: unset;\n    background: unset;\n  }\n\n  .select__menu {\n    ::-webkit-scrollbar {\n      -webkit-appearance: none;\n      width: 7px;\n    }\n\n    ::-webkit-scrollbar-thumb {\n      border-radius: 4px;\n      background-color: rgba(0, 0, 0, 0.5);\n      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);\n    }\n  }\n"], ["\n  .select__indicator-separator {\n    width: unset;\n    background: unset;\n  }\n\n  .select__menu {\n    ::-webkit-scrollbar {\n      -webkit-appearance: none;\n      width: 7px;\n    }\n\n    ::-webkit-scrollbar-thumb {\n      border-radius: 4px;\n      background-color: rgba(0, 0, 0, 0.5);\n      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
