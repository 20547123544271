import { __assign, __makeTemplateObject, __rest } from "tslib";
import React, { forwardRef, useRef } from "react";
import { FormElement } from "../FormElement";
import styled from "styled-components";
import { getElementId, populateTestId } from "../../../utils";
import { Colors } from "../../../constants";
export var Input = forwardRef(function (_a, ref) {
    var children = _a.children, value = _a.value, placeholder = _a.placeholder, error = _a.error, id = _a.id, label = _a.label, _b = _a.isFormElement, isFormElement = _b === void 0 ? true : _b, className = _a.className, rest = __rest(_a, ["children", "value", "placeholder", "error", "id", "label", "isFormElement", "className"]);
    var idRef = useRef(id || getElementId("input"));
    populateTestId(rest, rest.name, "input");
    var component = (React.createElement(StyledInput, __assign({ type: "text", placeholder: placeholder, "aria-label": placeholder, value: value, id: idRef.current, error: error }, rest, { ref: ref, className: isFormElement ? undefined : className })));
    return isFormElement && rest.type !== "hidden" ? (React.createElement(FormElement, { error: typeof error === "string" ? error : undefined, label: label, id: idRef.current, className: className }, component)) : (component);
});
export var StyledInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  background: ", ";\n  border: 1px solid ", ";\n  padding: 8px 16px;\n  border-radius: 4px;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 20px;\n  letter-spacing: 0.1px;\n  color: ", ";\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n\n  &.is-invalid {\n    border-color: ", ";\n  }\n\n  &.disabled,\n  &:disabled {\n    border-color: ", ";\n    color: ", ";\n  }\n"], ["\n  width: 100%;\n  background: ", ";\n  border: 1px solid ", ";\n  padding: 8px 16px;\n  border-radius: 4px;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 20px;\n  letter-spacing: 0.1px;\n  color: ", ";\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n\n  &.is-invalid {\n    border-color: ", ";\n  }\n\n  &.disabled,\n  &:disabled {\n    border-color: ", ";\n    color: ", ";\n  }\n"])), Colors.Gray1, function (_a) {
    var error = _a.error;
    return (error ? Colors.Red : Colors.Gray3);
}, Colors.PrimaryBlack, Colors.Gray5, function (_a) {
    var error = _a.error;
    return (error ? Colors.Red : Colors.PrimaryBlue);
}, Colors.Red, Colors.Gray1, Colors.Gray5);
var templateObject_1;
